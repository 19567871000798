import { React, useEffect, useState } from "react";
import articleDetails from "./Articledetails";
import CommenHero from "../Component/HeroHeading/CommenHero";
import HeroDetails from "../Component/HeroHeading/HeroDetails";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import { useParams, Link, useLocation } from "react-router-dom";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// console.log("first")
const SearchArticleList = () => {
  // console.log(first)
  const [articleData, setArticleData] = useState([]);
  const [topicName, setTopicName] = useState("");
  const {state }=useLocation();

  let headings = {
    n1: "Article",
    n2: "Li",
    n3: "sts",
  };

  useEffect(() => {
    setTopicName(state.search)
    setArticleData(state.topics)
  }, []);

  return (
    <>
      <Navbar />
      <CommenHero
        allName={headings}
        name1="Article"
        name2="Article List"
        Name3="Article List"
      />
      <div className="privacy_padd">
        <div>
          <h2 className="heading2 mb-0">Tagged in "{topicName}"</h2>
          <h6 className="mt-3 text-black heading6">
            {articleData.length} article(s)
          </h6>
        </div>
        {
        articleData.map((val) => {
          return (
            <div className="mt-5" key={val._id}>
              <Link to={`/articledetails/${val._id}`} className="ancor_color">
                <h4 className="heading4 mb-1">
                  {" "}
                  <span>{val.titleName}</span>
                </h4>
              </Link>
              {/* <p className="mb-3 main_para">
                {val.tags.map((e) => e).join(" , ")}
              </p> */}
              <pre style={{ whiteSpace: "break-spaces" }} className="artice_para">{val.short_Description}</pre>
            </div>
          );
        })}
      </div>

      <Footer />
    </>
  );
};

export default SearchArticleList;
