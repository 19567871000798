import { apiRequest } from "./apiRequest";

export const sessionEnrollmentCheck = async (sessionId, userId) => {
  const api = await apiRequest({
    url: `/v1/api/groupsessions/sessions/checkenrollment`,
    method: "post",
    body: {
      sessionId,
      userId,
    },
    header: true,
  });
  return api;
};

export const getAllAvailableSessions = async (
  topicName,
  mentorName,
  priceStart,
  priceEnd,
  startDate,
  endDate,
  page,
  limit
) => {
  const api = await apiRequest({
    url: `v1/api/users/sessions?topicName=${topicName}&mentor=${mentorName}&priceStart=${priceStart}&priceEnd=${priceEnd}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`,
    method: "get",
    header: true,
  });
  return api;
};

export const getAllPublicAvailableSessions = async (
  timeZoneName,
  offset,
  limit
) => {
  const api = await apiRequest({
    url: `v1/api/users/groupsessionpublic?timeZoneName=${timeZoneName}&page=${offset}&limit=${limit}`,
    method: "get",
    header: false,
  });
  return api;
};

export const cancelSessionApiCall = async (sessionId, cancelledReason) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/sessions/${sessionId}`,
    method: "patch",
    body: { cancelledReason },
    header: true,
  });
  return api;
};

export const getAllMentorTopics = async (userId) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/topics/${userId}`,
    method: "get",
    header: true,
  });
  return api;
};

export const getEnrollments = async (sessionId) => {
  const api = await apiRequest({
    url: `/v1/api/groupsessions/sessions/${sessionId}/enrollments`,
    method: "get",
    header: true,
  });
  return api;
};

export const updateGroupSession = async (data) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/groupsessions/sessions`,
    method: "put",
    body: data,
    header: true,
  });
  return api;
};

export const addGroupSession = async (data) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/groupsessions/sessions`,
    method: "post",
    body: data,
    header: true,
  });
  return api;
};

export const getTopicByIdApi = async (topicId) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/topicbyId/${topicId}`,
    method: "get",
    header: true,
  });
  return api;
};

export const deleteGroupSession = async (sessionId) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/groupsessions/sessions/${sessionId}`,
    method: "delete",
    header: true,
  });
  return api;
};

export const addTopicApi = async (data) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/topics`,
    method: "post",
    body: data,
    header: true,
  });
  return api;
};

export const updateTopicApi = async (data) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/groupsessions/topics`,
    method: "put",
    body: data,
    header: true,
  });
  return api;
};

export const manteeTopicDetailApi = async (id) => {
  const api = await apiRequest({
    url: `/v1/api/groupsessions/topics/${id}/sessions`,
    method: "get",
    header: true,
  });
  return api;
};

export const getGroupSessionsDetailsByEndpoint = async (endpoint) => {
  const api = await apiRequest({
    url: `/v1/api/groupsessions/${endpoint}`,
    method: "get",
    header: true,
  });
  return api;
};

export const withdrawSessionApiCall = async (sessionId, withdrawalReason) => {
  const api = await apiRequest({
    url: `/v1/api/groupsessions/sessions/${sessionId}/withdraw`,
    method: "post",
    body: { withdrawalReason },
    header: true,
  });
  return api;
};

export const deleteTopicApi = async (topicId) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/topics/${topicId}`,
    method: "delete",
    header: true,
  });
  return api;
};

export const getSessionById = async (sessionId, timeZoneName, userId) => {
  const api = await apiRequest({
    url: `/v1/api/mentors/groupsessions/${sessionId}?timeZoneName=${timeZoneName}&userId=${userId}`,
    method: "get",
    header: false,
    issessionpage: true,
  });
  return api;
};
