import React, { useState, useEffect } from "react";
import ReviewModleRating from "./ReviewModleRating";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useUser from "../../hooks/useUser";

const ReviewCard = () => {
  const [sessionId, setSessionId] = useState("");
  const [showRatingModal, setShowRatingModal] = useState(false);
  const { user } = useUser(); 
  const handleCloseRatingModal = () => {
    setShowRatingModal(false);
    handleGetMentorData();
  };
  const handleShowRatingModal = (id) => {
    setShowRatingModal(true);
    setSessionId(id);
  };
  const [mentorData, setMentorData] = useState([]);
  const id = user?._id;
  const token = JSON.parse(localStorage.getItem("userData")).token;

  useEffect(() => {
    handleGetMentorData();
  }, []);

  const handleGetMentorData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/user/${id}/my-mentors`,
        // `http://localhost:3001/v1/user/${id}/my-mentors`,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
            // 'Authorization': 'Bearer ' + this.state.AccessToken
          },
          body: JSON.stringify(),
        }
      );
      const data = await response.json();

      if (data && data.success) {
        setMentorData(data.data);
        console.log("mentor data", data.data);
      }
    } catch (error) {
      console.error("Error fetching mentor data", error);
    }
  };

  if (mentorData.length > 0) {
    return (
      <>
        <div className="card mt-3">
          <div className="card-header border-0">
            <h5 className="mb-0">My Mentors</h5>
          </div>
          <div className="card-body card-mentor-rating pt-0 position-relative ">
            {mentorData.map((myMentorData, index) => (
              <div key={index} className="mb-2">
                <div className="d-flex mb-0">
                  {/* Avatar */}
                  <div className="avatar me-2">
                    {/*  <img
                      className="avatar-img rounded-circle"
                      src={myMentorData.mentor.profilePicture}
                      alt=""
                    /> */}
                    <LazyLoadImage src={myMentorData.mentor.profilePicture} alt="" className="avatar-img rounded-circle" crossorigin="anonymous"/>
                  </div>
                  {/* Info */}
                  <div className="ms-2 w-100 ">
                    <div className="d-sm-flex justify-content-between">
                      <div>
                        <Link className="h6 mb-0" to={`/userprofile/${myMentorData.mentor?._id}`}>
                          {myMentorData.mentor?.firstName}{" "}
                          {myMentorData.mentor?.lastName}
                        </Link>
                        <p className="d-block small mb-0">
                          {" "}
                          {myMentorData.mentor?.headline}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-0">
                  {/* Info */}
                  <div className=" w-100">
                    <div className="d-sm-flex pt-2 justify-content-between align-items-center">
                      <p className="mb-0">
                        {" "}
                        <small className="text-dull">
                          {" "}
                          {myMentorData.skill}
                        </small>
                      </p>

                      {
                        myMentorData?.review ? (
                          "") : (
                          <p className="mb-0">
                            <Link
                              className="text-dull"
                              onClick={() =>
                                handleShowRatingModal(myMentorData._id)
                              }
                            >
                              <small>
                                Rate{" "}
                                <i
                                  className="fa fa-external-link"
                                  aria-hidden="true"
                                ></i>
                              </small>
                            </Link>
                          </p>
                        )
                      }

                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginTop: "10px",
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        
        <ReviewModleRating
          show={showRatingModal}
          onHide={handleCloseRatingModal}
          sessionId={sessionId}
        />
      </>
    );
  } else {
    return "";
  }
};

export default ReviewCard;
