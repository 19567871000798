import React from "react";
import moment from "moment";

function Experiance(props) {
  console.log("props.userData.data.experience",  props.userData.data.experience);
  let experience = props.userData.data.experience;
  return (
    <>
      <div className="col-sm-6 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between border-0">
            <h5 className="card-title mt-0 mb-0 text-purple">Experience</h5>
          </div>

          <div className="card-body position-relative pt-0">
            {/* <!-- Experience item START --> */}
            {experience.map((val, ind) => {
              return (
                <div className="d-flex">
                  {/* <!-- Info --> */}

                  <div key={ind} >
                    <h6 className="card-title mb-1">
                      {val.jobTitle}
                    </h6>
                    <p className="mb-0 small">
                      {" "}
                      <b>{val?.employementType}, {val?.companyName}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <small>
                      {val.startDate && (val.present || val.endDate) ? (
                        <>
                          {moment(new Date(val?.startDate)).format("MMM YYYY")} - 
                          {val?.present ? "Present" : moment(new Date(val?.endDate)).format("MMM YYYY")}
                        </>
                      ) : null}

                       
                      </small>
                    </p>
                   
                  </div>
                </div>
              );
            })}
          </div>
          {/* <!-- Card body END --> */}
        </div>
      </div>
    </>
  );
}

export default Experiance;
