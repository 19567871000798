import React,{useState} from "react";
import { Modal } from "react-bootstrap";

const ShareGroupSessionModal = ({
  isShareModalOpen,
  setIsShareModalOpen,
  sessionId,
}) => {
    const [isCopied, setIsCopied] = useState(false);
  const shareurl =
    `${process.env.REACT_APP_REDIRECT_URL}/groupsessiondetails/` + sessionId;

  const CopyShareLink = (e) => {
    navigator.clipboard.writeText(shareurl);
    
  };
  return (
    <div>
      <Modal show={isShareModalOpen} onHide={() => setIsShareModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Share Group Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Share this link with your friends to invite them to this group
            session
          </p>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              value={shareurl}
              id="shareurl"
            />
            <button
              className="btn btn-primary"
              onClick={(e) => {
                CopyShareLink(e);
                setIsCopied(true);
              }}
            >
              {isCopied ? "Copied" : "Copy"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareGroupSessionModal;
