import { apiRequest } from "./apiRequest";

export const getUserNudgeBlock = async () => {
    const api = await apiRequest({
        url: `/v1/user/home/nudge-block`,
        method: "get",
        header: true,
    });
    return api;
};

export const getAllNews = async () => {
    const api = await apiRequest({
        url: `/v1/user/home/latest-news`,
        method: "get",
        header: true,
    });
    return api;
};

export const adminLoginToLearnet = async (data) => {
    const api = await apiRequest({
        url: `/v1/admin/auth/learnet-admin-login`,
        method: "post",
        body: data,
        header: false,
    });
    return api;
}