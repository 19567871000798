import React, { useEffect, useState } from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { padding } from '@mui/system';
import { toast } from "react-toastify";
import axios from "axios";
import { decode as base64_decode } from "base-64";
const baseUrl = process.env.REACT_APP_REDIRECT_FAQURL



const FaqList = () => {

        const [FaqData, setFaqData] = useState([]);

        useEffect(() => {

                FillFaq();

        }, [])

        const FillFaq = async () => {



                try {

                        const result = await axios.get(`${baseUrl}/faqs`);                       
                        setFaqData(result.data.data)
                        console.log("Faqdata ", result.data.data)

                }
                catch (err) {

                }


        }

        return (
                <MDBContainer className="faq_section" style={{ maxWidth: '1100px' }}>
                        <MDBAccordion alwaysOpen initialActive={1}>
                                {
                                        FaqData.map((obj, index) => (
                                                <MDBAccordionItem collapseId={index+1} headerTitle={obj.question}style={{ padding: "10px" }}>
                                                       <div dangerouslySetInnerHTML={{ __html: base64_decode(obj.answer) }} />
                                                </MDBAccordionItem>
                                        ))}
                        </MDBAccordion>
                       
                </MDBContainer>
        )
}

export default FaqList