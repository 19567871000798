import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: "",
};

const searchQuerySlice = createSlice({
    name: "searchQuery",
    initialState,
    reducers: {
        setSearchQuery: (state, action) => {
            state.search = action.payload;
        },
        //empty search query
        emptySearchQuery: (state) => {
            state.search = "";
        },
    },
});

export const { setSearchQuery } = searchQuerySlice.actions;
export const selectSearchQuery = (state) => state.searchQuery.search;
export default searchQuerySlice.reducer;