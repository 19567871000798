import React, { useState, useEffect } from "react";
import { MyProfileAddSkills, MyProfileUpdateSkill, MyProfileForMentee } from "../../../Services/MyProfileAuth";
import { Modal, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
import { userProfile } from "../../../Services/UserAuth";
import { SignupStepSkillsLists } from "../../../Services/obAuth";
export const MenteesSkill = (props) => {

  return (
    <>
      <div className="card">

        <div className="card-header d-flex justify-content-between border-0">
          <h5 className="card-title mt-0 mb-0 text-purple">I am interested in learning</h5>
        </div>
        {/* <!-- Card header END --> */}
        {/* <!-- Card body START --> */}
        <div className="card-body position-relative pt-0">
          {/* <!-- Experience item START --> */}
          {props.userData.data.menteeTopics.map((val, ind) => {
            return (
              <div className="d-flex justify-content-between align-items-start d-block mt-2" key={ind}>
                {/* <!-- Info --> */}
                <div>
                  <h6 className="mb-0">
                    {val}
                  </h6>

                </div>
                {/* <a

                  className="btn btn-sm btn-primary-soft btn-xs btn_edit ms-2"
                  onClick={e => handleEdit(val)}
                  data-bs-toggle="modal"
                  data-bs-target="#modalEdit"
                >
                  <i className="bi bi-pencil-fill pe-1"></i> Edit{" "}
                </a> */}

              </div>
            );
          })}

        </div>
        {/* <!-- <button className="btn btn-danger-soft me-2" type="button"> <i className="bi bi-pencil-fill pe-1"></i> Edit </button> --> */}

      </div>

      {/* <!-- Experience item END --> */}

      {/* <!-- Card body END --> */}


    </>
  );
};
