import React, { useEffect, useState } from "react";
import { GetUrlExtractedData } from "../../../Services/MyProfileAuth";
import { Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const WallPost = ({
  urls,
  setUrls,
  extractedUrlData,
  setExtractedUrlData,
  isPreview = false,
}) => {
  const fetchPreviewData = (url) => {
    GetUrlExtractedData(url)
      .then((res) => {
        console.log("res", res);
        setExtractedUrlData(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        setExtractedUrlData(null);
      });
  };

  useEffect(() => {
    if (urls && urls.length > 0) {
      fetchPreviewData(urls[0]);
    } else {
      if (setExtractedUrlData) setExtractedUrlData(null);
      if (setUrls) setUrls(null);
    }
  }, [urls]);

  const handleOnClick = () => {
    if (extractedUrlData && !isPreview) {
      const url = extractedUrlData.url;

      window.open(url, "_blank");
    } else {
      console.log("else section");
    }
  };

  return (
    <div>
      {extractedUrlData && (
        <div className="card p-4 mt-4" onClick={handleOnClick}>
          <Row>
            {extractedUrlData.og_image && (
              <Col lg={3}>
                {/*   <img src={extractedUrlData.og_image} alt="Preview" /> */}
                <LazyLoadImage
                  src={extractedUrlData.og_image}
                  alt="Preview"
                  crossOrigin="anonymous"
                />
              </Col>
            )}
            <Col lg={extractedUrlData.og_image ? 8 : 11}>
              <h5>{extractedUrlData.og_title || extractedUrlData.title}</h5>
            </Col>
            {isPreview && (
              <Col lg={1} className="wall-post-close">
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    urls = null;
                    setUrls(null);
                    setExtractedUrlData(null);
                  }}
                />
              </Col>
            )}
          </Row>
          <p className="mt-2 mb-0">
            {extractedUrlData.og_description || extractedUrlData.description}
          </p>
        </div>
      )}
    </div>
  );
};

export default WallPost;
