import React from 'react'
import { Link, NavLink } from 'react-router-dom'

function Footer() {
    return (
        <>
            <footer>
                <div className="footer_padding background-color-white">
                    <div>
                        <div className="footer_section footer_section_second">
                            <div className='footer_barnd'>
                                <a>© Learnet LLC </a>
                            </div>
                            <div className="privacytext">
                                <span className="privacy_section">
                                    <Link to="/privacy-policy">Privacy Policy</Link></span> <span className="privacy_section">
                                    <Link to="/term-and-condtion">Terms of Use</Link></span> <span className="privacy_section">
                                    <Link to="/cookie-policy">Cookie Policy</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer