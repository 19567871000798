import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slice/UserData';
import searchQuery from './slice/searchQuery';

const store = configureStore({
  reducer: {
    user: userReducer,
    searchQuery: searchQuery,

    // other reducers if any
  },
});

export default store;
