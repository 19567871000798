import React, { useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const Hero = (props) => {
  const { user, updateUser } = useUser();
  const [searchedArticle, setSearchedArticle] = useState({
    isVisiable: true,
    value: "",
  });
  const [articleSuggestion, setArticleSuggestion] = useState([]);
  const articleSearchRef = useRef();
  const searchRef = useRef();
  const navigate = useNavigate();

  const getSuggestedArticles = () => {
    axios
      .get(
        `${BASE_URL}/v1/Topics/search-article?search=${searchedArticle.value}`
      )
      .then((res) => setArticleSuggestion(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleSearchArticle = (e) => {
    setSearchedArticle((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
    getSuggestedArticles();
  };

  window.addEventListener("click", (e) => {
    if (e.target.contains(articleSearchRef.current)) {
      setSearchedArticle((prevState) => ({ ...prevState, isVisiable: false }));
    }
  });

  const handleKeyClick = (e) => {
    if (e.keyCode === 13) {
      axios.get(`${BASE_URL}/v1/Topics/AllTopic?keyword=${(e.target
        ? e.target.value.toLowerCase()
        : searchRef.current.value.toLowerCase())}`).then((res) => {
          console.log(res, "----")
          const Link = `/articles`;
          navigate(Link,{state: { topics: res.data.topics, search: e.target.value }});
        });
    }
  };

  return (
    <>

      <div className="hero-section-privacy term_condition_bg">
        <div>
          <nav aria-label="breadcrumb " className="breadcrumb_page">
            <ol className="breadcrumb ">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.name}
              </li>
            </ol>
          </nav>
          <h1 className="mb-4 privacy_heading">
            <span className="span">{props.firstName}</span>
            {props.lastName}
          </h1>
          <h5 className="mb-4 privacy_heading5">
            Hi {user ? user?.fullName : "User"},
            We're here to help.
          </h5>
          <div className="height d-flex justify-content-center align-items-center">
            <div ref={articleSearchRef} className="search position-relative">
              <input
                type="text"
                ref={searchRef}
                className="form-control"
                placeholder="How can we help?"
                value={searchedArticle.value}
                onKeyDown={handleKeyClick}
                onClick={() =>
                  setSearchedArticle((prevState) => ({
                    ...prevState,
                    isVisiable: true,
                  }))
                }
                onChange={handleSearchArticle}
              />
              <i
                style={{ cursor: "pointer" }}
                className="fa fa-search"
                onClick={() => handleKeyClick({ keyCode: 13 })}
              ></i>
              {searchedArticle.isVisiable && (
                <div
                  div
                  className="searched-articles position-absolute w-100"
                >
                  {articleSuggestion.map((article, index) => (
                    <Link key={index} to={`/articledetails/${article._id}`}>
                      {article.titleName}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
