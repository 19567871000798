import { ThreeDots } from "react-loader-spinner";

const Loader = () => {
    return (
        <div className="loader-parent" >
            <div class="defaultloader">
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#92278f"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                >Joining ...
                </ThreeDots>
            </div>
        </div>
    );
}
export default Loader;
