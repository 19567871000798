import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import { getAllPublicAvailableSessions } from "../../../Services/GroupSessionApiCall";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import moment from "moment";


const GroupSessionCarousel = () => {
  const [sessionData, setSessionData] = useState([]);
  const navigate = useNavigate();
  const Image_Url = process.env.REACT_APP_IMAGE_URL;
  const [offset, setOffset] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);
  const limit = 6;

  useEffect(() => {
    fetchGroupSessionData();
  }, [offset, limit, totalSessions]);

  const fetchGroupSessionData = async () => {
    try {
      const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await getAllPublicAvailableSessions(timeZoneName, offset, limit);
      if (response && response.data) {
        setSessionData((prev) =>
          offset === 0
            ? response?.data?.data?.topics
            : [...prev, ...response?.data?.data?.topics]
        );
        console.log(
          sessionData
        )
        setTotalSessions(response?.data?.data?.totalTopics);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1},
    { width: 480, itemsToShow: 1 , itemsToScroll: 1},
    { width: 768, itemsToShow: 2 , itemsToScroll: 2},
    { width: 1024, itemsToShow: 3 , itemsToScroll: 3},
    { width: 1200, itemsToShow: 3 , itemsToScroll: 3},
  ];

  const formatDate = (date) => {
    return moment.utc(date).format("ddd, DD MMM YYYY");
  };

  const fromTime = (date) => {
    return moment.utc(date).format("hh:mm A");
  };

  const handelClickTitle = (sessionData) => {
    navigate(`/groupsessiondetails/${sessionData.sessionDetail._id}`);
  };

  const handleOnClickNext = () => {
    if (offset + limit < totalSessions) {
      setOffset(offset + 1);
    }
  };



  return (
    <div className="">
      {/* /// heading upcoming group session you can enroll in  /// */}
      <div className="text-center mb-5">
        <h1>
          Upcoming Group Session
          <br /> you can <b>enroll in</b>
        </h1>
      </div>

      <Carousel 
      itemsToScroll={3}
      breakPoints={breakPoints} 
      renderArrow={({ type, onClick }) => {
        const pointer =
          type === "PREV" ? "fa-chevron-left" : "fa-chevron-right";
        return (
          <button
            className="carousel-btn"
            onClick={
              type === "PREV"
                ? () => {
                    onClick();
                
                  }
                : () => {
                    onClick();
                    handleOnClickNext();
                  }
            }
            // disabled={
            //   type === "PREV" ? offset === 0 : offset + limit >= totalSessions
            // }
          >
            <i className={`fas ${pointer}`}></i>
          </button>
        );
      }}
        renderPagination={() => {
          return <div></div>;
        }
      }

      >
    
        {sessionData ? (
          sessionData.map((val, index) => {
            return (
              <div className="card h-100 max-width-360">
                <span
                  onClick={() => handelClickTitle(val)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="position-relative detail-topic-card ">
                    <LazyLoadImage
                      src={`${Image_Url}${val.coverImage}`}
                      alt=""
                      className="img-fluid rounded-top min-h-227"
                      crossOrigin="anonymous"
                    />
                  </div>
                  <div className="card-body position-relative pt-4">
                    <h6 className="text-truncate w-100">{val?.topicName}</h6>
                    <p className="mb-0 small topic-card-p">
                      <i className="fa-solid bi bi-calendar-check pe-1"></i>{" "}
                      {val.sessionDetail?.sessionDate !== undefined
                        ? formatDate(val.sessionDetail?.sessionDate)
                        : "No Date"}{" "}
                      {""} at {""}{" "}
                      {val.sessionDetail?.sessionDate !== undefined
                        ? fromTime(val.sessionDetail?.sessionDate)
                        : "No Time"}{" "}
                      ( {moment.tz(val.sessionDetail?.timeZone).format("z")})
                    </p>
                    <div className="d-flex justify-content-between mb-1">
                      <p className="small para_pur_col gs_name topic-card-p mb-0">
                        <i className="fa-solid fa-user pe-1 "></i>{" "}
                        {val?.mentorName}
                      </p>
                      {/* <a
                        class="nav-link mb-0 p-0 mt-1"
                        onClick={() =>
                          handleOpenShareModal(val?.sessionDetail?._id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <i class="bi bi-reply-fill flip-horizontal ps-1"></i>
                        Share
                      </a> */}
                    </div>

                    <div className="d-flex justify-content-between row">
                      <div className="d-flex justify-content-between topic-card-p fs-15 col-9">
                        <p className="small para_pur_col gs_name topic-card-p">
                          <i class="fa-solid fa-clock pe-1"></i>{" "}
                          {val?.sessionDuration !== undefined
                            ? val?.sessionDuration
                            : "0 min"}
                        </p>
                        <p className="small para_pur_col gs_name topic-card-p">
                          <i class="fa-solid fa-comment-dollar pe-1"></i> ${" "}
                          {val?.sessionDetail?.amountPerSeatDollar} per seat
                        </p>
                      </div>
                      <div className="col-3"></div>
                    </div>
                  </div>
                </span>
              </div>
            );
          })
        ) : (
          <div className="text-center">
            <h3>No Group Session Available</h3>
          </div>
        )}
      </Carousel>
    </div>
  );
};

export default GroupSessionCarousel;
