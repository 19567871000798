import React from 'react'
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getAllNews } from '../../../Services/homePageService';
import useUser from '../../../hooks/useUser';
export const LeatestNews = () => {
  const navigate = useNavigate();
  const [latestNews, setLatestNews] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { user } = useUser();
  useEffect(() => {
    setLoaded(false);
    getAllNews().then((res) => {
      //console.log(res);
      if (res.data.success) {
        setLatestNews(res.data.data);
      }
    }).finally(() => {
      setLoaded(true);
    });

  }, []);

  const handleUserClick = (e, id) => {
    e.preventDefault();
    const userData = user; //JSON.parse(localStorage.getItem("userData"));
    if (userData?._id === id) {
      navigate("/myprofile");
    }
    else {
      navigate(`/userprofile/${id}`);
    }
  }

  const handlePostClick = (e, id) => {
    e.preventDefault();
    navigate(`/userpost/${id}`);
  }


  return (
    <>
      {loaded ?
        <div className="card hide_scrollbar" style={{ maxHeight: "400px", overflow: "auto" }}>
          {/* <!-- Card header START --> */
            /* <!-- Card header END --> */
            /* <!-- Card body START --> */}
          <div className="card-body">
            <h5 className="card-title mb-4 mt-0" style={{ fontWeight: "600" }}>Latest Updates</h5>

            {

              latestNews.map((val, ind) => {
                return (
                  <div className="mb-3" key={ind}>
                    <h6 className="mb-0" style={{ fontWeight: "600" }}>
                      <p className='mb-0'>
                      {val?.postId ? (
                        <a
                        onClick={(e) => handlePostClick(e, val?.postId?._id)}
                        style={{ cursor: "pointer" }}
                        >
                          <b>
                            {val.user?.firstName + " " + val.user?.lastName}
                          </b>
                          <span> {val.title}</span>
                        </a>
                      ) : (
                        <>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleUserClick(e, val.user._id)}
                          >
                            <b>
                              {val.user?.firstName + " " + val.user?.lastName}
                            </b>
                         
                          <span> {val.title}</span>
                          </a>
                        </>
                      )}
                      </p>

                    </h6>
                    <small style={{ fontSize: "11px" }}>{moment.utc(val.createdAt).local().startOf('seconds').fromNow()}</small>
                  </div>
                )
              })

            }

          </div>
        </div> : ''
      }



    </>
  )

}
