import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { userProfile } from "../../../Services/UserAuth";
import { ApplyForMentorAPI } from "../../../Services/MyProfileAuth";
import { useSelector,useDispatch} from 'react-redux';
import { selectUser,setUser } from "../../../redux/slice/UserData.js";

const MentorApplication = ({  setApply,  setMentorApproval }) => {
    const userProfileData = useSelector(selectUser);
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = userData?.token || null
    const [isFetching, setisFetching] = useState(false);
    const [description, setDescription] = useState("")
    const [skills, setSkills] = useState("")
    const [certifications, setCertifications] = useState("")
    const [hours, setHours] = useState("")
    const [materials, setMaterials] = useState("")

    const loadProfile = () => {
        try {
            if (token !== undefined) {
                userProfile(token)
                    .then((res) => {
                        if (res.status === true) {
                            /* let userProfileData = JSON.stringify(res.data);
                            localStorage.setItem("userProfileData", userProfileData); */
                            dispatch(setUser(res.data));
                            setMentorApproval(res.data.mentorApproval)
                        }
                    })
                    .catch();
            }
        } catch (error) { }
    }

    const ApplyForMentor = () => {
        setisFetching(true)
        var data = [
            {
                "question": "Please tell us why you want to become a mentor? (word limit 50)",
                "answer": description
            },
            {
                "question": "What are the key skills or topics that you would like others to learn from you? Please limit to top three only. (word limit 50)",
                "answer": skills
            },
            {
                "question": "List any licenses or certifications related to the skills or topics you've mentioned above. (word limit 50)",
                "answer": certifications
            },
            {
                "question": "Outside of your work, how many hours a week can you commit to mentoring?",
                "answer": hours
            },
            {
                "question": "Please provide links to reference materials, if available (e.g. YouTube videos, TikTok videos, websites, etc.)",
                "answer": materials
            }
        ]

        ApplyForMentorAPI(data, userData.token).then((res) => {
            if (res !== undefined) {
                if (res.success === true) {
                    toast.success("Application submitted successfully");
                    setisFetching(false)
                    setApply(true)
                    setMentorApproval("Pending")
                    loadProfile()
                    window.$("#modalformentor").modal("hide")
                }
            }
            setisFetching(false)
        })
    }


    useEffect(() => {
        if (userProfileData) {
            //get comma separated skills
            const commaSeparatedSkills = userProfileData?.skills?.map(skill => skill?.title).join(', ');
            setSkills(commaSeparatedSkills);
        }
    }, []);

    return (
        <div
            class="modal fade"
            id="modalformentor"
            tabIndex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog" style={{ maxWidth: "700px" }}>
                <div class="modal-content">
                    {/* <!-- Modal header --> */}
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalforprofile">
                            &nbsp;Mentor Application
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={e => { window.$("#modalformentor").modal("hide") }}
                            style={{ border: "none", background: "white" }}
                        >&times;</button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <p>Please tell us why you want to become a mentor? (word limit 50) * </p>
                        </div>

                        <div>
                            <textarea style={{ width: "100%", border: '1px solid black', minHeight: "100px" }}
                                rowsMin={6}
                                cols={50}
                                aria-label="minimum height"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                class="form-control"
                            >
                            </textarea>
                            <p></p>
                        </div>

                        <div>
                            <p> What are the key skills or topics that you would like others to learn from you? Please limit to top three only. (word limit 50) *</p>
                        </div>

                        <div>
                            <input 
                            type="text" 
                            class="form-control" 
                            //value={skills} 
                            onChange={e => { 
                                console.log("skills", e.target.value)
                                setSkills(e.target.value)
                            }} 
                            value={skills}
                            style={{ width: "100%", border: '1px solid black', height: "40px" }} />
                            <p></p>
                        </div>
                        <div>
                            <p>Outside of your work, how many hours a week can you commit to mentoring? *</p>
                        </div>

                        <div>
                            <input type="text" class="form-control" value={hours} onChange={e => setHours(e.target.value)} style={{ width: "100%", border: '1px solid black', height: "40px" }} />
                            <p></p>
                        </div>

                        <div>
                            <p>Please provide links to reference materials, if available (e.g. YouTube videos, TikTok videos, websites, etc.)</p>
                        </div>

                        <div>
                            <input type="text" class="form-control" value={materials} onChange={e => setMaterials(e.target.value)} style={{ width: "100%", border: '1px solid black', height: "40px" }} />
                            <p></p>
                        </div>


                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <p></p>
                        {(description !== "" && skills !== "" && hours !== "") ?
                            <button type="button" class="btn btn-sm btn-primary-soft"
                                onClick={ApplyForMentor}
                            >
                                <span className="spinnercontainer">{isFetching ? <span id="spinner"><i className="fa fa-spinner fa-spin"></i></span> : null} </span>Apply <span className="spinnercontainer"></span>
                            </button>
                            :
                            <button type="button" class="btn btn-sm" disabled>
                                Apply
                            </button>
                        }
                    </div>
                </div>

                {/* <!-- Form END --> */}
            </div>
            {/* <!-- Modal footer --> */}
        </div>
    )
}

export default MentorApplication