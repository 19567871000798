import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Reviews from "./Reviews";

function RightBooking({ availabilities, price, timeZone, reviews }) {
  
  let userBookingData = JSON.parse(localStorage.getItem("userBookingData"));
  let navigate = useNavigate();
  const [averageRating, setAverageRating] = useState(0);
  const [pricing, setPrice] = useState(price.length > 0 ? price[0].price : 0);
  const [skill, setSkill] = useState("");
  const [timings, setTimings] = useState(
    availabilities !== null && availabilities.length > 0
      ? moment(
          new Date().toISOString().slice(0, 10) +
            " " +
            availabilities[0]?.timings[0].startTime
        ).format("hh:mm A") +
          " - " +
          moment(
            new Date().toISOString().slice(0, 10) +
              " " +
              availabilities[0]?.timings[0].endTime
          ).format("hh:mm A")
      : ""
  );
  const bookNow = () => {
    //store pricing and skill in local storage
    localStorage.setItem("mentorPricing", pricing);
    localStorage.setItem("mentorSkill", skill);
    navigate("/datetime", { state: { price: pricing, skill: skill } });
  };
  useEffect(() => {
    var tempskill = userBookingData.skills;
    if (tempskill != undefined && tempskill != null && tempskill.length > 0) {
      setPrice(tempskill[0].rate);
      setSkill(tempskill[0].title);
    }

    if (reviews?.length > 0) {
      let totalRating = 0;
      reviews.map((review) => {
        totalRating = totalRating + review.review.rating;
      });
      setAverageRating(Number((totalRating / reviews.length).toFixed(1)));
    }
  }, []);

  const handleChange = (e) => {
    setSkill(e.target.value);
    var tempskill = userBookingData.skills;
    const filterPrice = tempskill.filter(
      (price) => price.title === e.target.value
    );
    setPrice(filterPrice[0].rate);
  };
  const handleAvailibility = (day) => {
    var tempavailabilities = availabilities;
    const filterAvailibility = tempavailabilities.filter(
      (avail) => avail.day === day
    );
    console.log("filterAvailibility", filterAvailibility);
    setTimings(
      moment(
        "01-01-2022 " + filterAvailibility[0]?.timings[0].startTime
      ).format("hh:mm A") +
        " - " +
        moment(
          "01-01-2022 " + filterAvailibility[0]?.timings[0].endTime
        ).format("hh:mm A")
    );
  };

  const maxLength = 15;

  const truncateText = (text) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <div className="col-md-6 col-lg-12">
        <div className="card ">
          <div className="card-header back-leranet-h d-flex justify-content-between border-0">
            <h5 className="card-title text-purple mb-0">1-on-1 Sessions</h5>
          </div>
          {/* <div className="card-body position-relative pt-2 pb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="card-title mb-0">
                  Availability Days:{" "}
                </h6>
              </div>
              <p>{(availabilities !== null && availabilities != undefined && availabilities.length > 0) ?
                <select
                  name="Javascript"
                  id="Javascript"
                  className="border-none font-size text-black bold"
                  onChange={e => handleAvailibility(e.target.value)}

                >{availabilities.map((val) =>
                  <option value={val.day} key={val.day}>{val.day}</option>
                )}
                </select>
                : "Not Available"
              }</p>
            </div>
            {(availabilities !== null && availabilities != undefined && availabilities.length > 0) &&
              <div className="d-flex justify-content-between border-bottom-1 mobleflex-wrap">
                
                <div>
                  <h6 className="card-title mb-0">
                    Availability Timing: {" "}
                  </h6>
                </div>
                {console.log("timingssssssssssss", timings)}
                <p className="text-black">{timings} {timeZone.shortCode}</p>
              </div>
            }
            <div className="d-flex justify-content-between border-bottom-1 py-3">
              
              <div>
                <h6 className="card-title mb-0">
                  Skills or Topics :{" "}
                </h6>
              </div>

              <select
                name="Javascript"
                id="Javascript"
                className="border-none text-black bold"
                onChange={handleChange}
                style={{
                  width: "100%",
                  maxWidth: "100px"
                }}

              >{userBookingData.skills.map((val) =>
                <option
                  value={val.title}
                  key={val.title}

                > {truncateText(val.title)}</option>
              )}
              </select>

            </div>
            <div className="d-flex justify-content-between mt-3">
              
              <div>
                <h6 className="card-title mb-0 ">
                  Pricing :{" "}
                </h6>
              </div>

              <p className="text-black">{pricing > 0 ? "$ " + pricing : "Free"} </p>
            </div>
            <div className="d-flex justify-content-center">
              {(availabilities !== null && availabilities != undefined && availabilities.length > 0) ?
                <button type="button" className="btn  btn-purple btn-primary-soffft" onClick={bookNow}>
                  Book Now
                </button>
                : "This mentor has not yet provided the availability for the topics and skills they mentor. But you can message them to check if they are available to mentor on the topics and skills you're looking for."
              }
            </div>
          </div> */}
          <div className="card-body position-relative pt-2 pb-2">
            <div className="d-flex flex-column">
              {/* Availability Days */}
              <div className="user-profile mb-2">
                <h6 className="card-title mb-0">Availability Days</h6>

                {availabilities !== null &&
                availabilities != undefined &&
                availabilities.length > 0 ? (
                  <select
                    name="Javascript"
                    id="Javascript"
                    className="border-none font-size text-black profile-availability"
                    onChange={(e) => handleAvailibility(e.target.value)}
                  >
                    {availabilities.map((val) => (
                      <option value={val.day} key={val.day}>
                        {val.day}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p>Not Available</p>
                )}
                <hr />
              </div>

              {/* Availability Timing */}
              {availabilities !== null &&
                availabilities != undefined &&
                availabilities.length > 0 && (
                  <div className="user-profile mb-2">
                    <h6 className="card-title mb-0">Availability Timing</h6>
                    <p className="text-black profile-availability">
                      {timings} {timeZone.shortCode}
                    </p>
                    <hr />
                  </div>
                )}

              {/* Skills or Topics */}
              <div className="user-profile mb-2">
                <h6 className="card-title mb-0">Skills or Topics</h6>
                <select
                  name="Javascript"
                  id="Javascript"
                  className="border-none text-black profile-availability"
                  onChange={handleChange}
                >
                  {userBookingData.skills.map((val) => (
                    <option value={val.title} key={val.title}>
                      {val.title}
                    </option>
                  ))}
                </select>
                <hr />
              </div>

              {/* Pricing */}
              <div className="user-profile mb-2">
                <h6 className="card-title mb-0">Pricing</h6>
                <p className="text-black profile-availability">
                  {pricing > 0 ? "$" + pricing : "Free"}
                </p>
                <hr />
              </div>

              {/* Book Now Button */}
              <div className="d-flex justify-content-center">
                {availabilities !== null &&
                availabilities != undefined &&
                availabilities.length > 0 ? (
                  <button
                    type="button"
                    className="btn  btn-purple btn-primary-soffft"
                    onClick={bookNow}
                  >
                    Book Now
                  </button>
                ) : (
                  "This mentor has not yet provided the availability for the topics and skills they mentor. But you can message them to check if they are available to mentor on the topics and skills you're looking for."
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Reviews --> */}
        {reviews?.length > 0 && (
          <div className="card mt-5">
            <div className="card-header back-leranet-h d-flex justify-content-between border-0">
              <h5 className="card-title text-purple mb-0">
                Reviews ({reviews.length})
              </h5>
              <p className="ratings_five mb-0">
                {" "}
                <b>{averageRating}</b> <span>Out of 5</span>{" "}
              </p>
            </div>
            <div className="card-max-heigthscroll">
              <div className="card-body pt-4">
                {reviews.map((review) => {
                  return (
                    <div className="card mb-4">
                      <div className="card-body position-relative pt-3 pb-3">
                        <div className="d-flex mb-0">
                          {/* <!-- Info --> */}
                          <div className="w-100 ">
                            <div className="d-sm-flex justify-content-between">
                              <div>
                                <a
                                  className="h6 mb-0"
                                  target="_blank"
                                  href={`/userprofile/${review.review.user._id}`}
                                >
                                  {review.review.user.firstName}{" "}
                                  {review.review.user.lastName}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-0">
                          {/* <!-- Info --> */}
                          <div className=" w-100">
                            <div className="d-sm-flex justify-content-between align-items-center">
                              <div className="userlist_sect userstarlist">
                                <div>
                                  <ul className="d-flex list-unstyled">
                                    <li>
                                      <i
                                        className={`fa-solid fa-star text-${
                                          review.review.rating >= 1
                                            ? "warning"
                                            : "muted"
                                        }`}
                                      ></i>
                                    </li>
                                    <li>
                                      <i
                                        className={`fa-solid fa-star text-${
                                          review.review.rating >= 2
                                            ? "warning"
                                            : "muted"
                                        }`}
                                      ></i>
                                    </li>
                                    <li>
                                      <i
                                        className={`fa-solid fa-star text-${
                                          review.review.rating >= 3
                                            ? "warning"
                                            : "muted"
                                        }`}
                                      ></i>
                                    </li>
                                    <li>
                                      <i
                                        className={`fa-solid fa-star text-${
                                          review.review.rating >= 4
                                            ? "warning"
                                            : "muted"
                                        }`}
                                      ></i>
                                    </li>
                                    <li>
                                      <i
                                        className={`fa-solid fa-star text-${
                                          review.review.rating >= 5
                                            ? "warning"
                                            : "muted"
                                        }`}
                                      ></i>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <p className="d-block small para_sec">
                              {review.review.comment}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RightBooking;
