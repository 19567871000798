import { toast } from "react-toastify";
import axios from "axios";
const API_Base_Url =    process.env.REACT_APP_BACKEND_URL;

// user profile getting
export const userProfile = async (token) => {
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    
    const response = await fetch(`${API_Base_Url}/v1/user/view/profile`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: !token ? userData?.token : token,
        // 'Authorization': 'Bearer ' + this.state.AccessToken
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const uploadCoverImageTopic = async (formdata) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: user.token,
      },
    };
    const response = await axios
      .post(`${API_Base_Url}/v1/uploads?for=GroupSession`, formdata, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.success === false)
            return toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          return toast.error("You are not authorized");
        } else if (error.response.status === 404) {
          return toast.error("Url not found");
        } else if (error.response.status === 500) {
          return toast.error("Internal server error please try after sometime");
        }
      });
    return response.data;
  } catch (error) {
    return toast.error(
      "Something went wrong , Please check your internet connection."
    );
  }
};

// create new post start here
// post photo
export const uploadPost = async (formdata) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: user.token,
      },
    };

    const response = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/uploads?for=Post`,
        formdata,
        headers
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.success === false)
            return toast.error(error.response.data.message, "Hello");
        } else if (error.response.status === 401) {
          return toast.error("You are not authorized");
        } else if (error.response.status === 404) {
          return toast.error("Url not found");
        } else if (error.response.status === 500) {
          return toast.error("Internal server error please try after sometime");
        }
      });
    return response.data;
  } catch (error) {
    return toast.error(
      "Something went wrong , Please check your internet connection."
    );
  }
};
// Post All the Data
export const PostImage = async (reqdata, linkedinId) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    // const formData = new FormData();
    // formData.append("file", reqdata, reqdata.name);
    const response = await fetch(
      `${API_Base_Url}/v1/user/${linkedinId}/new-post`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(reqdata),
      }
    );
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

// Get all posts
export const getAllPost = async (linkedinId,pageNo=1,pageSize=10) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/${linkedinId}/posts?pageSize=${pageSize}&pageNo=${pageNo}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();
    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const CancelSessionApi = async (sessionId) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  try {
    const response = await fetch(`${API_Base_Url}/v1/sessions`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: user.token,
      },
      body: JSON.stringify({
        status: "Cancel",
        sessionId: sessionId,
        cancelByUserId: user.data._id,
        reason: "Not Available",
      }),
    });

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();

    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const getOnePost = async (postId) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  var header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (user !== null && user.token !== undefined && user.token !== null) {
    header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: user.token,
    };
  }
  try {
    const response = await fetch(`${API_Base_Url}/v1/user/posts/${postId}`, {
      method: "GET",
      headers: header,
    });

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();

    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

// Get Search Result
export const search = async (value, ps, pe, sb) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/search/skill?keyword=${value}&priceStart=${ps}&priceEnd=${pe}&sortBy=${sb}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
        body: JSON.stringify(),
      }
    );
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

// Get Post Coments
export const AllComments = async () => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(`${API_Base_Url}/v1/user/all/comments`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: user.token,
        // 'Authorization': 'Bearer ' + this.state.AccessToken
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

// Get Post Coments
export const PostComments = async () => {
  try {
    const response = await fetch(`${API_Base_Url}/v1/user/all/comments`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'Authorization': 'Bearer ' + this.state.AccessToken
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

//  Add Coments
export const AddComments = async (reqdata, linkedinId) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/${linkedinId}/post/new-comment`,
      //  `http://localhost:3001/v1/user/${linkedinId}/post/new-comment`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
        body: JSON.stringify(reqdata),
      }
    );
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

//Like
export const AddLike = async (post) => {
  // alert(post)

  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(`${API_Base_Url}/v1/user/postLike/${post}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: user.token,
      },
      // body: JSON.stringify(reqdata),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};
// Add Reply

export const AddReply = async (linkedinId) => {
  // alert(linkedinId)

  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/add-reply/${linkedinId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
        // body: JSON.stringify(reqdata),
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

// All Learner

export const getAllLearner = async (token) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/profile/load/${user.data._id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();

    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const upload = async (formdata, uploadfor) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: user.token,
      },
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/uploads?for=${uploadfor}`,
        formdata,
        headers
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.success === false)
            return toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          return toast.error("You are not authorized");
        } else if (error.response.status === 404) {
          return toast.error("Url not found");
        } else if (error.response.status === 500) {
          return toast.error("Internal server error please try after sometime");
        }
      });
    return response.data;
  } catch (error) {
    return toast.error(
      "Something went wrong , Please check your internet connection."
    );
  }
};

export const UpdateProfilePic = async (reqdata) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/profile/profile-picture`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
        body: JSON.stringify(reqdata),
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const UpdateBannerImage = async (reqdata) => {
  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/profile/banner-image`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
        body: JSON.stringify(reqdata),
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const getAvailability = async (id, token) => {
  try {
    const response = await fetch(`${API_Base_Url}/v1/availabilities/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token,
        // 'Authorization': 'Bearer ' + this.state.AccessToken
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const otherProfile = async (id, token) => {
  try {
    const response = await fetch(`${API_Base_Url}/v1/user/profile/load/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token,
        // 'Authorization': 'Bearer ' + this.state.AccessToken
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const otherProfileforAdmin = async (id) => {
  try {
    const response = await fetch(`${API_Base_Url}/v1/user/profile/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const getPrice = async (linkedinId) => {
  try {
    const response = await fetch(
      `${API_Base_Url}/v1/user/${linkedinId}/pricing`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const CreateSessionAPI = async (reqdata) => {
  // alert(linkedinId)
  console.log("reqdata", reqdata);

  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(`${API_Base_Url}/v1/sessions`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: user.token,
      },
      body: JSON.stringify(reqdata),
    });

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const EnrollSessionAPI = async (reqdata) => {
  // alert(linkedinId)

  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(`${API_Base_Url}/v1/api/groupsessions/sessions/enrollments`, {
  //  const response = await fetch(`http://localhost:3001/v1/api/groupsessions/sessions/enrollments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: user.token,
      },
      body: JSON.stringify(reqdata),
    });

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();

    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    return toast.error("Something went wrong , Please try again later.");
  }
}

export const CaptureTransaction = async (reqdata) => {
  // alert(linkedinId)

  let user = JSON.parse(localStorage.getItem("userData"));

  try {
    const response = await fetch(`${API_Base_Url}/v1/user/transactions`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: user.token,
      },
      body: JSON.stringify(reqdata),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

// my newtworks getting
export const MyNetworks = async (token, id) => {
  try {
    const response = await fetch(`${API_Base_Url}/v1/user/${id}/networks`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token,
      },
      body: JSON.stringify(),
    });
    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const CreateCheckoutSessionApi = async (amount, session) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  try {
    const response = await fetch(
      `${API_Base_Url}/v1/strip/create-checkout-session`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
        body: JSON.stringify({
          amount: Number(amount),
          productName: session.skill,
          redirectUrl: process.env.REACT_APP_REDIRECT_URL,
          session: session,
        }),
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();

    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};

export const GetCheckoutSessionApi = async (sessionId) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  try {
    const response = await fetch(
      `${API_Base_Url}/v1/strip/get-checkout-session/${sessionId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: user.token,
        },
      }
    );

    if (response.status === 401) {
      toast.error("Your Session has been expired, Please login again.");
      return window.setTimeout(function () {
        localStorage.clear();
      }, 1000);
    }

    const result = await response.json();

    if (response.status === 200) {
      return result;
    } else if (response.status === 400) {
      toast.error(result.message);
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong , Please try again later.");
  }
};
export const GetMyMentorList = async (token, id) => {
  try {
    const headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token,
      },
    };

    const response = await axios.get(
      `${API_Base_Url}/v1/user/${id}/mentors`,
      headers
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};
