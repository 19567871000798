import React, { useState, useEffect } from 'react';

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when user scrolls down 20px
    const toggleVisibility = () => {
        if (window.scrollY > 20) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the event listener for scroll
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Scroll to the top of the page when button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            {isVisible &&
                <button className="btn btn-primary scroll-to-top-btn rounded-circle" onClick={scrollToTop}>
                    <i class="fa-solid fa-arrow-up"></i>
                </button>
            }
        </div>
    );
}

export default ScrollToTop;
