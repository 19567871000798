import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log("action.payload", action, state)
      state.user = action.payload;
    },
    updateUserFields: (state, action) => {
      const { fieldName, fieldValue } = action.payload;

      if (state.user) {
        state.user[fieldName] = fieldValue;
      }
      console.log("action.payload user", action, state)
    },
    updateMultipleFields: (state, action) => {
      const fieldsToUpdate = action.payload;
      if (state.user) {
        Object.keys(fieldsToUpdate).forEach(fieldName => {
          if (state.user.hasOwnProperty(fieldName)) {
            state.user[fieldName] = fieldsToUpdate[fieldName];
          }
        });
      }
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, logout, updateMultipleFields, updateUserFields } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
