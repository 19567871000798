import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const CommenHero = (props) => {
    const navigate = useNavigate();

    return (
        <div className="hero-section-privacy term_condition_bg">
            <div>
                <nav aria-label="breadcrumb " className="breadcrumb_page">
                    <ol className="breadcrumb ">
                        <li className="breadcrumb-item"><Link to="/Home-page">Home</Link></li>
                        {props.name1 ? <li className="breadcrumb-item"><Link to="/article">{props.name1} </Link></li> : ""}
                        {props.name3 ? <li className="breadcrumb-item" onClick={() => navigate(-1)}><Link>{props.name3} </Link></li> : ""}

                        {props.name2 ? <li className="breadcrumb-item active" aria-current="page">{props.name2}</li> : ""}

                    </ol>
                </nav>
                <h1 className="mb-4 privacy_heading"> {props.allName.n1} <span className="span">{props.allName.n2}</span><span className="span">{props.allName.n3}</span></h1>
                {/* <h5 className="mb-4 privacy_heading5">Hi {(localStorage.getItem("userProfileData")!==null)&&JSON.parse(localStorage.getItem("userProfileData")).fullName}, We're here to help.</h5> */}
                <div className="height d-flex justify-content-center align-items-center">
                    {/* <div className="search">
                        <input type="text" className="form-control" placeholder="How can we help?"/>
                        <i className="fa fa-search"></i>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default CommenHero