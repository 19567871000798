import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import Store from "./redux/store";
import { HelmetProvider } from "react-helmet-async";

// original code
const root = ReactDOM.createRoot(document.getElementById("root"));

//const client = ZoomVideo.createClient();
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <RecoilRoot>
        <Provider store={Store}>
          <App />
        </Provider>
      </RecoilRoot>
    </BrowserRouter>
  </HelmetProvider>
);

reportWebVitals();
