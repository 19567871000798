import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios'; // Import axios for making API requests
import useUser from '../../hooks/useUser'; // Import useUser hook to get user data

const ReviewModleRating = ({ show, onHide, sessionId }) => {
  const { user } = useUser(); // Get user data using useUser hook
const token = JSON.parse(localStorage.getItem("userData")).token;
const userId = user?._id;

  const [rating, setRating] = useState(0);
  const handleRatingSelect = (selectedRating) => {
    setRating(selectedRating);
  };

  const [sessionReview, setSessionReview] = useState('');

  const SubmitReviewAndRating = async (e) => {
    e.preventDefault();
    try {
      const data = {
        user: userId,
        rating: rating,
        comment: sessionReview,
    
      };

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/sessions/${sessionId}/reviews`, data, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (response && response.data.success === true) {
        toast.success('Review submitted successfully.');

        onHide();

      }
    } catch (error) {
      console.error('Error submitting review and rating', error);
    }
   
  };
 const handleChange = (e) => {
    setSessionReview(e.target.value);
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Rate your Mentor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <p>Rating : </p>
            <div>
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  onClick={() => handleRatingSelect(value)}
                  style={{
                    cursor: 'pointer',
                    color: rating >= value ? 'gold' : 'gray',
                  }}
                >
                  ★
                </span>
              ))}
            </div>
           
          </div>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Review : </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={sessionReview}
              onChange={ handleChange}
              className="col-md-12"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onHide} className="btn btn-primary-soft btn-sm">
            Close
          </button>
          <Button variant="primary" onClick={(e)=> SubmitReviewAndRating(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReviewModleRating;
