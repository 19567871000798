import { useSelector, useDispatch } from 'react-redux';
import { logout, selectUser, setUser,updateMultipleFields,updateUserFields } from '../redux/slice/UserData'; // Import your selector and action creator

const useUser = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const updateUser = (userData) => {
    dispatch(setUser(userData));
  };

  const updateUserField = (fields) => {
    console.log("fields",fields)
    dispatch(updateUserFields({ fieldName : fields?.fieldName,fieldValue: fields?.fieldValue}));
  }

  const logoutUser = () => {
    dispatch(logout());
  }

  return { logoutUser,user, updateUser,updateMultipleFields,updateUserField };
};

export default useUser;
