import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import NotificationListItem from "./NotificationItem";
import axios from "axios";
import socket from "../api.js";
import { Modal } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useUser from "../hooks/useUser.js";
const API_Base_Url = process.env.REACT_APP_BACKEND_URL;

const NotificationDropdown = ({
  handleNotification,
  showNotification,
  isNewNotif,
  isAcceptRejectButtonClicked,
  navigate,
  // userData,
  calculateTimeAgo,
  getSocketId,
  Active,
  normal,
  notificationRef,
  showNotificationNew,
  //handleSubmitMessage,
  Loader,
  setIsAcceptRejectButtonClicked,
  isMobileView,
}) => {
  const [isRejectModelOpen, setIsRejectModelOpen] = useState(false);
  const [isFetching, setisFetching] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNewNotificationReceived, setIsNewNotificationReceived] = useState(0);
  const [sessionid, setSessionId] = useState({});
  const [description, setDescription] = useState("");
  const [notiId, setNotiId] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [rejectNotificationIndex, setRejecNotificationIndex] = useState(-1);
  const { user } = useUser();
  const [userProfileData, setUserProfileData] = useState(user);
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (user) {
      setUserProfileData(user);
    }
  }, [user]);

  const order_ids = (id1, id2) => {
    let room = id1 > id2 ? `${id1}-${id2}` : `${id2}-${id1}`;
    return room;
  };

  const getTime = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  };

  const getDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    let day = date.getDate().toString();
    month = month.length > 1 ? month : `0${month}`;
    day = day.length > 1 ? day : `0${day}`;
    return `${day}-${month}-${year}`;
  };
  const getTimezone = (date) => {
    let timezone = date.getTimezoneOffset();
    return timezone;
  };

  // FUNCTION USED TO ACCEPT/reject socKET cALL SESSION
  const handleSubmitMessage = (e, user_id, notificationid) => {
    if (isAcceptRejectButtonClicked) return;
    console.log("userProfileData roomf", userProfileData, user, user_id);
    const room = order_ids(
      userProfileData?._id || user?._id || userData?.data?._id,
      user_id
    );
    const roomf = { name: room };
    console.log("roomf-navbar", roomf);
    let mess;
    let messageg;
    if (typeof e === "string") {
      mess = e;
      let id = JSON.parse(e).id;
    } else {
      e.preventDefault();
    }

    if (mess.length > 0) {
      const dates = new Date();
      const time = getTime(dates);
      const date = getDate(dates);
      const timezone = getTimezone(dates);
      /* let data = {
                message: mess,
                time,
                date,
                sender: userData.data._id,
                room: roomf,
                userOnline: false,
                new: true,
                isSent: false,
                timezone,
                json: typeof e === "string" ? true : false,
            }; */
      let dataf = {
        time,
        date,
        timezone,
        message: mess,
        room: roomf.name,
        sender: userProfileData?._id,
        seen: false,
        userOnline: false,
        json: typeof e === "string" ? true : false,
      };
      if (messageg) {
        let dataInsideMessageg = "";
        if (messageg.json) {
          dataInsideMessageg = JSON.parse(messageg.message);
        } else {
          dataInsideMessageg = messageg.message;
        }
        dataf = { ...dataf, ...dataInsideMessageg };
      }
      socket.emit("send-message", dataf, ({ seen }) => {});
      socket.emit("accept-session", dataf);
      //socket.emit("receive-message", dataf); OPTIMIZE
    }

    // readNotification(notificationid);
  };

  const addDescription = (e) => {
    if (e.target.value !== "Other") {
      setDescription(e.target.value);
    } else {
      setDescription("");
    }

    setSelectedReason(e.target.value);
  };

  const openReject = (session, notification_id, index) => {
    setIsRejectModelOpen(true);
    window.$("#modalreject1").modal("show"); // useRef
    setSessionId(session);
    setNotiId(notification_id);
    setRejecNotificationIndex(index);
    // setIsAcceptRejectButtonClicked(false);
    //setNotificationClickedIndex(index);
  };

  const handleAcceptRejectButtonClicked = (index, status) => {
    //setNotificationClickedIndex(index);
    //find notification by index
    const notification = notifications[index];
    if (
      notification?.notificationType === "session" ||
      notification?.notificationType === "session-update"
    ) {
      const data = JSON.parse(notification.body);
      data.status = status || "Accepted";
      //get prevNotification, i.e. notifications excluding the current notification
      const prevNotifications = notifications.filter((item, i) => i !== index);
      //push the notification in prevNotifications at index  index
      prevNotifications.splice(index, 0, {
        ...notification,
        body: JSON.stringify(data),
      });
      setNotifications(prevNotifications);
    }

    //setIsAcceptRejectButtonClicked(true);
  };

  const getNotifications = async () => {
    try {
      const header = {
        headers: {
          authorization: userData?.token,
        },
      };
      const notifications = await axios.get(
        API_Base_Url + "/v1/user/notification",
        header
      );
      if (notifications !== undefined) {
        setNotifications(notifications.data.data.data);
        const count = notifications.data.data.data.reduce(
          (acc, notification) => {
            if (!notification.isRead) {
              return acc + 1;
            }
            return acc;
          },
          0
        );

        setIsNewNotificationReceived(count);

        //after 5 sec set the isAcceptRejectButtonClicked to false
        setTimeout(() => {
          //setIsAcceptRejectButtonClicked(false);
          //setNotificationClickedIndex(-1);
        }, 10000);
      }
    } catch (err) {
      console.log(err);
      //setIsAcceptRejectButtonClicked(false);
    }
  };

  const readAllNotification = async () => {
    try {
      const header = {
        headers: {
          authorization: userData?.token,
        },
      };
      const updatedNotification = await axios.patch(
        API_Base_Url + "/v1/user/notification",
        {},
        header
      );
      // getNotifications();
      // update isRead status of all notifications
      setNotifications((notification) =>
        notification.map((item) => {
          return { ...item, isRead: true };
        })
      );

      /*  const count = notifications.reduce((acc, notification) => {
                 if (!notification.isRead) {
                     return acc + 1;
                 }
                 return acc;
             }, 0);
             
             setIsNewNotificationReceived(count); */
      setIsNewNotificationReceived(0);
    } catch (err) {
      console.log(err);
    }
  };

  const readNotification = async (id) => {
    try {
      const header = {
        headers: {
          authorization: userData?.token,
        },
      };
      const updatedNotification = await axios.patch(
        API_Base_Url + "/v1/user/notification/" + id,
        {},
        header
      );
      // getNotifications();
      // update isRead status of the notification
      setNotifications((notification) =>
        notification.map((item) => {
          if (item._id === id) {
            return { ...item, isRead: true };
          }
          return item;
        })
      );

      setIsNewNotificationReceived((prev) => prev - 1);

      /*  const NotificationIndex = notifications.findIndex(i => i._id == id);
             notifications[NotificationIndex].isRead = true;
             setNotifications([...notifications]); */
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const RejectSession = async () => {
    setisFetching(true);
    // setIsAcceptRejectButtonClicked(true);

    const response = await fetch(`${API_Base_Url}/v1/sessions`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: userData.token,
      },
      body: JSON.stringify({
        status: "Reject",
        sessionId: sessionid.sessionId,
        rejectedByUserId: userProfileData?._id,
        reason: description,
      }),
    });

    let dataf = {
      status: "Rejected",
      id: sessionid?.sessionId,
      reason: description, // added extra on 06/06/23
      messageId: sessionid?.messageId,
    };
    //socket.emit("accept-session", dataf);

    const data = await response.json();

    if (data !== undefined) {
      /* handleSubmitMessage(
              JSON.stringify(dataf), 
              sessionid?.bookedby,
              sessionid?.sessionId
            ) */
      handleSubmitMessage(
        JSON.stringify({
          status: "Rejected",
          id: sessionid.sessionId,
          reason: description,
          mentor: sessionid.mentor,
          mentee: sessionid.bookedby,
          topic: sessionid.skill,
          duration: 30,
          type: 1,
          agenda: "Reject Notification",
          socketId: await getSocketId(sessionid.bookedby),
          hostemail: userProfileData?.email,
          sessionDate: sessionid.date,
          messageId: sessionid?.messageId,
        }),
        sessionid.bookedby,
        sessionid?.sessionId
      );
      if (rejectNotificationIndex >= 0) {
        handleAcceptRejectButtonClicked(rejectNotificationIndex, "Rejected"); // Changing Pending Status to Rejected
      }

      //readNotification(notiId);
      setTimeout(async () => {
        //window.location.reload();
        localStorage.setItem("messageupdated", JSON.stringify(dataf));
      }, 500);
      //window.$("#modalreject1").modal("hide")
      setIsRejectModelOpen(false);
      setisFetching(false);
      //setNotificationClickedIndex(-1);
      //setIsAcceptRejectButtonClicked(false);
    }
  };

  // add new notification in front
  socket.off("new-notification").on("new-notification", (data) => {
    console.log("new-notification 1", data);
    const newNotification = data?._doc;
    newNotification.minutes = 0;
    newNotification.json = true;
    newNotification.user = {
      profilePicture: data?.user?.profilePicture,
      firstName: data?.user?.firstName,
      lastName: data?.user?.lastName,
      _id: data?.user?._id,
    };

    const updatedNotifications = data?.previousNotifications || [];
    //console.log("updatedNotifications", updatedNotifications)
    let existingNotifications = notifications;
    // add new notification at front
    existingNotifications.unshift(newNotification);

    for (let notification of updatedNotifications) {
      const existingNotification = existingNotifications.findIndex(
        (item) => item._id == notification._id
      );
      if (existingNotification !== -1) {
        notification.minutes = Math.floor(
          (new Date().getTime() - new Date(notification.createdAt).getTime()) /
            60000
        );
        existingNotifications[existingNotification] = notification;
      }
      setNotifications(existingNotifications);
    }
    console.log("new-notification 2", existingNotifications);
    setNotifications(existingNotifications);
    //setIsAcceptRejectButtonClicked(false);
  });

  useEffect(() => {}, [notifications]);

  return (
    <>
      {isMobileView && (
        <>
          <div
            className={normal}
            // className={showNotification ? Active : normal}
            id="notifDropdown"
            onClick={handleNotification}
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            <p className="mb-0 haeder_haed  mobile-responsive-text">
              <span
                ref={notificationRef}
                className={`${isNewNotif > 0 && "badge-notif"} animation-blink`}
              ></span>
              {/* <i className="bi bi-bell-fill fs-6 icon_size"> </i>{" "} */}
              <span className="fs-6 icon_size">
                <svg
                  width="18"
                  height="22"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0598 22.0591H7.94029M16.1792 8.29435C16.1792 6.60944 15.5282 4.99355 14.3694 3.80214C13.2106 2.61073 11.6389 1.94141 10.0001 1.94141C8.36123 1.94141 6.7895 2.61073 5.63067 3.80214C4.47186 4.99355 3.82083 6.60944 3.82083 8.29435C3.82083 11.5663 3.01802 13.8066 2.12121 15.2883C1.36473 16.5382 0.986496 17.1632 1.00037 17.3375C1.01572 17.5306 1.0555 17.6042 1.2068 17.7196C1.34345 17.8238 1.95945 17.8238 3.19144 17.8238H16.8086C18.0406 17.8238 18.6566 17.8238 18.7932 17.7196C18.9445 17.6042 18.9843 17.5306 18.9996 17.3375C19.0135 17.1632 18.6353 16.5382 17.8788 15.2883C16.982 13.8066 16.1792 11.5663 16.1792 8.29435Z"
                    stroke="#606060"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
            </p>
          </div>

          <div
            ref={notificationRef}
            style={{
              display:
                showNotification && showNotificationNew ? "block" : "none",
            }}
            className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0 dropdown-menu-notiffication"
            aria-labelledby="notifDropdown"
          >
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="m-0 ">
                  Notifications
                  {isNewNotif > 0 && (
                    <span className="badge bg-purple bg-opacity-10 text-white ms-2">
                      {isNewNotif} new
                    </span>
                  )}
                </h6>
                <Link
                  className="small text-purple"
                  to="#"
                  onClick={(e) => readAllNotification()}
                >
                  Mark All as Read
                </Link>
              </div>
              {isAcceptRejectButtonClicked ? (
                <div style={{ height: "20rem" }}>
                  <Loader />
                </div>
              ) : (
                <div
                  className="card-body p-0"
                  style={{
                    overflowY: "scroll",
                    height: "calc(100vh - 120px)",
                  }}
                >
                  <ul className="list-group list-group-flush list-unstyled p-2">
                    {/* <!-- Notif item --> */}
                    {notifications !== undefined &&
                      notifications !== null &&
                      notifications.length > 0 &&
                      notifications.map((item, index) => {
                        return (
                          item.body !== undefined && (
                            <li
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                readNotification(item._id);
                                if (
                                  item.notificationType === "session" &&
                                  JSON.parse(item.body).status === "Accepted"
                                ) {
                                  navigate(`/session`, {
                                    state: {
                                      sessionId: JSON.parse(item.body)
                                        .sessionId,
                                    },
                                  });
                                  // window.open(`/session`,"_blank");
                                } else if (item.notificationType === "post") {
                                  navigate(
                                    `/userpost/${JSON.parse(item.body).postId}`,
                                    {
                                      state: {
                                        postId: JSON.parse(item.body).postId,
                                      },
                                    }
                                  );
                                  // window.open(`/userpost/${JSON.parse(item.body).postId}`)
                                } else if (
                                  item.notificationType === "Message"
                                ) {
                                  navigate(
                                    `/messagelearner/${
                                      JSON.parse(item.body).sender
                                    }`,
                                    {
                                      state: {
                                        senderId: JSON.parse(item.body).sender,
                                      },
                                    }
                                  );
                                  // window.open(`/messagelearner/${JSON.parse(item.body).sender}`, '_blank');
                                } else if (
                                  item.notificationType === "SupportReply" ||
                                  item.notificationType === "SupportTicket"
                                ) {
                                  navigate(
                                    `/support-new/${
                                      JSON.parse(item.body).caseId
                                    }`,
                                    {
                                      state: {
                                        ticketId: JSON.parse(item.body)
                                          .ticketId,
                                      },
                                    }
                                  );
                                } else {
                                  console.log("notification item", item);
                                }
                              }}
                            >
                              <div
                                className={`list-group-item list-group-item-action rounded ${
                                  !item.isRead && "badge-unread"
                                } d-flex border-0 mb-1 p-3`}
                              >
                                {item.notificationType === "session" ||
                                item.notificationType === "session-update" ? (
                                  <div className="avatar text-center d-none d-sm-inline-block">
                                    <img
                                      className="avatar-img rounded-circle"
                                      src={
                                        item.user !== null
                                          ? item.user.profilePicture
                                          : "/assets/images/avatar.svg"
                                      }
                                      alt="Avatar"
                                      crossorigin="anonymous"
                                    />
                                    <LazyLoadImage
                                      src={
                                        item.user !== null
                                          ? item.user.profilePicture
                                          : process.env.REACT_APP_NO_IMAGE_URL
                                      }
                                      alt="Avatar"
                                      crossorigin="anonymous"
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar text-center d-none d-sm-inline-block">
                                    {/* <img
                                          className="avatar-img"
                                          src={"/assets/images/message.png"}
                                          alt="message"
                                        /> */}
                                    <span className="material-symbols-outlined">
                                      chat
                                    </span>
                                  </div>
                                )}
                                <div className="ms-sm-3">
                                  <div className="d-flex">
                                    <p className="small mb-2">
                                      {item.notificationType === "post"
                                        ? item.title
                                        : item.notificationType === "session"
                                        ? item.json &&
                                          JSON.parse(item.body).mentor ===
                                            userProfileData?._id
                                          ? "New Session has been booked by " +
                                            (item.user !== null
                                              ? item.user.firstName
                                              : "") +
                                            " " +
                                            (item.user !== null
                                              ? item.user.lastName
                                              : "")
                                          : "You have booked a new session with " +
                                            (item.user !== null
                                              ? item.user.firstName
                                              : "") +
                                            " " +
                                            (item.user !== null
                                              ? item.user.lastName
                                              : "")
                                        : item.notificationType ===
                                          "session-update"
                                        ? item.json &&
                                          JSON.parse(item.body).status ===
                                            "cancelled"
                                          ? `Your session on ${
                                              JSON.parse(item.body).skill
                                            } has been cancelled`
                                          : item.title
                                        : item.json &&
                                          JSON.parse(item.body).message}
                                    </p>
                                    <p className="small ms-3 text-nowrap">
                                      {/* {item.minutes <= 1
                                                  ? "Just now"
                                                  : item.minutes > 60
                                                    ? convertIntoHours(item.minutes) +
                                                    " ago"
                                                    : item.minutes + "min ago"} */}
                                      {calculateTimeAgo(item.minutes)}
                                    </p>
                                  </div>
                                  {/* commented previously unCommented on 030523 */}
                                  {item.notificationType === "session" ? (
                                    item.json &&
                                    JSON.parse(item.body).mentor ===
                                      userProfileData?._id &&
                                    JSON.parse(item.body).status ===
                                      "Pending" ? (
                                      //console.log("isAcceptRejectButtonClicked", isAcceptRejectButtonClicked)

                                      isAcceptRejectButtonClicked ? (
                                        ""
                                      ) : (
                                        <div className="d-flex">
                                          <button
                                            onClick={async () => {
                                              handleAcceptRejectButtonClicked(
                                                index
                                              );
                                              handleSubmitMessage(
                                                JSON.stringify({
                                                  status: "Accepted",
                                                  id: JSON.parse(item.body)
                                                    .sessionId,
                                                  mentor: JSON.parse(item.body)
                                                    .mentor,
                                                  mentee: JSON.parse(item.body)
                                                    .bookedby,
                                                  topic: JSON.parse(item.body)
                                                    .skill,
                                                  duration: 30,
                                                  type: 1,
                                                  agenda: "Accept Notification",
                                                  socketId: await getSocketId(
                                                    JSON.parse(item.body)
                                                      .bookedby
                                                  ),
                                                  hostemail:
                                                    userProfileData?.email,
                                                  sessionDate: JSON.parse(
                                                    item.body
                                                  ).date,
                                                  messageId: JSON.parse(
                                                    item.body
                                                  )?.messageId,
                                                }),
                                                JSON.parse(item.body).bookedby,
                                                item._id
                                              );
                                            }}
                                            className="btn btn-primary-soft btn-sm mb-0 me-2"
                                          >
                                            Accept
                                          </button>
                                          {/* <button */}
                                          <a
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              /*  openReject(JSON.parse(item.body).sessionId); */
                                              /*  setIsAcceptRejectButtonClicked(
                                                                                                 false
                                                                                             ); */
                                              openReject(
                                                JSON.parse(item.body),
                                                item._id,
                                                index
                                              );
                                            }}
                                            className="btn-sm reject-option"
                                          >
                                            Decline
                                          </a>
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        style={{
                                          textTransform: "capitalize",
                                          color: "purple",
                                        }}
                                      >
                                        {item.json &&
                                          (JSON.parse(item.body).status ===
                                          "Rejected"
                                            ? "Declined"
                                            : JSON.parse(item.body).status)}
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </li>
                          )
                        );
                      })}

                    {/* <!-- Notif item --> */}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {!isMobileView && (
        <li className="nav-item dropdown">
          <NavLink
            // className={showNotification ? Active : normal}
            className={normal}
            id="notifDropdown"
            to={"/"}
            onClick={handleNotification}
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            <p className="mb-0 haeder_haed  mobile-responsive-text">
              <span
                ref={notificationRef}
                className={`${isNewNotif > 0 && "badge-notif"} animation-blink`}
              ></span>
              {/* <i className="bi bi-bell-fill fs-6 icon_size"> </i> */}
              <span className="fs-6 icon_size">
                
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0598 22.0591H7.94029M16.1792 8.29435C16.1792 6.60944 15.5282 4.99355 14.3694 3.80214C13.2106 2.61073 11.6389 1.94141 10.0001 1.94141C8.36123 1.94141 6.7895 2.61073 5.63067 3.80214C4.47186 4.99355 3.82083 6.60944 3.82083 8.29435C3.82083 11.5663 3.01802 13.8066 2.12121 15.2883C1.36473 16.5382 0.986496 17.1632 1.00037 17.3375C1.01572 17.5306 1.0555 17.6042 1.2068 17.7196C1.34345 17.8238 1.95945 17.8238 3.19144 17.8238H16.8086C18.0406 17.8238 18.6566 17.8238 18.7932 17.7196C18.9445 17.6042 18.9843 17.5306 18.9996 17.3375C19.0135 17.1632 18.6353 16.5382 17.8788 15.2883C16.982 13.8066 16.1792 11.5663 16.1792 8.29435Z"
                    stroke=  "#606060"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}&nbsp; 
              Notifications{" "}
            </p>
          </NavLink>
          <div
            ref={notificationRef}
            style={{
              display:
                showNotification && showNotificationNew ? "block" : "none",
            }}
            className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0"
            aria-labelledby="notifDropdown"
          >
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="m-0 ">
                  Notifications
                  {isNewNotif > 0 && (
                    <span className="badge bg-purple bg-opacity-10 text-white ms-2">
                      {isNewNotif} new
                    </span>
                  )}
                </h6>
                <Link
                  className="small text-purple"
                  to="#"
                  onClick={(e) => readAllNotification()}
                >
                  Mark All as Read
                </Link>
              </div>
              {isAcceptRejectButtonClicked ? (
                <div style={{ height: "20rem" }}>
                  <Loader />
                </div>
              ) : (
                <div className="card-body p-0">
                  <ul
                    style={{ overflowY: "scroll", height: "20rem" }}
                    className="list-group list-group-flush list-unstyled p-2"
                  >
                    {/* <!-- Notif item --> */}
                    {notifications !== undefined &&
                      notifications !== null &&
                      notifications.length > 0 &&
                      notifications.map((item, index) => {
                        return (
                          item.body !== undefined && (
                            <NotificationListItem
                              key={index + item.toString()}
                              item={item}
                              index={index}
                              //userData={userData}
                              isAcceptRejectButtonClicked={
                                isAcceptRejectButtonClicked
                              }
                              handleAcceptRejectButtonClicked={
                                handleAcceptRejectButtonClicked
                              }
                              handleSubmitMessage={handleSubmitMessage}
                              //setIsAcceptRejectButtonClicked={setIsAcceptRejectButtonClicked}
                              openReject={openReject}
                              readNotification={readNotification}
                              navigate={navigate}
                              calculateTimeAgo={calculateTimeAgo}
                              getSocketId={getSocketId}
                            />
                          )
                        );
                      })}

                    {/* <!-- Notif item --> */}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </li>
      )}

      <Modal
        show={isRejectModelOpen}
        onHide={() => setIsRejectModelOpen(false)}
      >
        <div className="modal-content">
          {/* <!-- Modal header --> */}
          <Modal.Header closeButton>
            <Modal.Title>Decline Session</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div>
              <p>Reason </p>
            </div>

            <div>
              <select
                className="form-control"
                value={selectedReason}
                onChange={(e) => addDescription(e)}
              >
                <option value="">Select a reason</option>
                <option value="Sorry, I am not Available Anymore">
                  Sorry, I am not Available Anymore
                </option>
                <option value="Can You Look for Another Time?">
                  Can You Look for Another Time?
                </option>
                <option value="I don't Mentor This Topic/Skill Anymore">
                  I don't Mentor This Topic/Skill Anymore
                </option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div>
              {selectedReason === "Other" ? (
                <>
                  <textarea
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      marginTop: "10px",
                    }}
                    className="form-control"
                    rowsMin={6}
                    cols={50}
                    aria-label="minimum height"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder="Please provide your reason for declining the session ..."
                  ></textarea>
                  <p></p>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <p></p>
            {description !== "" && !isFetching ? (
              <button
                type="button"
                className="btn btn-sm btn-primary-soft"
                onClick={RejectSession}
              >
                <span className="spinnercontainer">
                  {isFetching ? (
                    <span id="spinner">
                      <i className="fa fa-spinner fa-spin"></i>
                    </span>
                  ) : null}{" "}
                </span>
                Decline <span className="spinnercontainer"></span>
              </button>
            ) : (
              <button type="button" className="btn btn-sm" disabled>
                Decline
              </button>
            )}
          </div>
        </div>

        {/* <!-- Form END --> */}
        {/* <!-- Modal footer --> */}
      </Modal>
    </>
  );
};

export default React.memo(NotificationDropdown);
