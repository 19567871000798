import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from '../Navbar';
import Footer from '../Footer';
import CommenHero from '../HeroHeading/CommenHero';
import HeroDetails from '../HeroHeading/HeroDetails';
import ArticleParaList from './ArticledetailslList/ArticleParaList';
import ArticleDetailsLinkList from './ArticledetailslList/ArticleDetailsLinkList';
import ArticleDetailsUlList from './ArticledetailslList/ArticleDetailsUlList';
import ArticleDetailsListLink from './ArticledetailslList/ArticleDetailsListLink';
import { BsCheckLg } from 'react-icons/bs';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
// import { convert } from 'html-to-text';
import parse from "html-react-parser";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const ArticleDetails = () => {

    const [articleData, setArticleData] = useState([])
    const [long_Description, setLong_description] = useState("")

    const { id } = useParams();


    const getArticleList = async () => {

        console.log(id, "id")
        // const data = await axios.get(`${BASE_URL}/Topics/getSigleTopicArticles/${id}`)
        // setArticleData(data.data.data)

        // console.log(data.data.data, "data.data.data")
        const data = await axios.get(`${BASE_URL}/v1/Topics/get_topic_articles/${id}`)
        setArticleData(data.data.data)
        setLong_description(base64_decode(data.data.data.Long_Description))
    }

        // Long_Description = base64_decode(articleData.Long_Description)
        ;


    // var ld = convert(long_Description);
    console.log(long_Description, "articleData")
    useEffect(() => {
        getArticleList()
    }, [])

    let headings = {
        n1: "Article",
        n2: "Det",
        n3: "ail",
    }
    return (
        <>
            <Navbar />
            <CommenHero allName={headings} name1="Articles" name2="Article Detail" name3="Article List" />
            <section>
                <div className="privacy_padd">
                    <div>
                        <div>
                            <h2 className="heading2 mb-2">{articleData.titleName}</h2>
                            {/* <h6 className="mt-3  heading6">Last updated: 24 days ago</h6> */}
                        </div>
                    </div>
                    <div className="mt-3">
                        <p className="artice_para">{parse(long_Description)}
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ArticleDetails