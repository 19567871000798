const Lerner_List=[
    {
        iconC:"",
        heading:"New to your role",
        text:"Appear more learned on the new skills expected of you or gain advanced knowledge of existing skills."
    },
    {
        iconC:"card_icon2",
        heading:"Stuck with something at work",
        text:"Shy to ask your colleagues or want to avoid being judged? Meet with an expert to learn and move ahead."
    },
    {
        iconC:"card_icon3",
        heading:"Want to learn a hot \n topic",
        text:"SAP S/4HANA ERP,  Veeva CRM, AWS Cloud Architecture, Cybersecurity Engineering, Mergers & Acquisitions, Org Change Management ..."
    },
    {
        iconC:"card_icon4",
        heading:"Just get some professional advice...\n on anything",
        text:"Raise capital for a new venture, explore a career change, become a better leader or a manager,  learn a new language."
    },
]

export default Lerner_List;