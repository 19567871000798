import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useUser from '../hooks/useUser';

const NotificationListItem = ({
    item,
    index,
    //userData,
    isAcceptRejectButtonClicked,
    handleAcceptRejectButtonClicked,
    handleSubmitMessage,
    setIsAcceptRejectButtonClicked,
    openReject,
    readNotification,
    navigate,
    calculateTimeAgo,
    getSocketId
}) => {
    const {user} = useUser();
   // const [userProfileData, setUserProfileData] = useState(user);
    return (
        <li key={index} style={{ cursor: "pointer" }} onClick={(e) => {
            readNotification(item._id);
            if (item.notificationType === "session" && JSON.parse(item.body).status === "Accepted") {
                navigate(`/session`, {
                    state: {
                        sessionId: JSON.parse(item.body).sessionId,
                    },
                });
            } else if (item.notificationType === "post") {
                navigate(`/userpost/${JSON.parse(item.body).postId}`, {
                    state: {
                        postId: JSON.parse(item.body).postId,
                    },
                });
            } else if (item.notificationType === "Message") {
                navigate(`/messagelearner/${JSON.parse(item.body).sender}`, {
                    state: {
                        senderId: JSON.parse(item.body).sender,
                    },
                });
            } else if (item.notificationType === "SupportReply" || item.notificationType === "SupportTicket") {
                navigate(`/support-new/${JSON.parse(item.body).caseId}`, {
                    state: {
                        ticketId: JSON.parse(item.body).ticketId,
                    },
                });
            }
        }}>
            <div className={`list-group-item list-group-item-action rounded ${!item.isRead && "badge-unread"} d-flex border-0 mb-1 p-3`}>
                {item.notificationType === "session" || item.notificationType === "session-update" ? (
                    <div className="avatar text-center d-none d-sm-inline-block">
                        {/* <img className="avatar-img rounded-circle" src={item.user !== null ? item.user.profilePicture :
                            "/assets/images/avatar.svg"} alt="Avatar" /> */}
                        <LazyLoadImage src={item.user !== null ? item.user.profilePicture : process.env.REACT_APP_NO_IMAGE_URL}
                            alt="Avatar" className="avatar-img rounded-circle" crossorigin="anonymous" />
                    </div>
                ) : (
                    <div className="avatar text-center d-none d-sm-inline-block">
                        <span className="material-symbols-outlined">chat</span>
                    </div>
                )}
                <div className="ms-sm-3">
                    <div className="d-flex">
                        <p className="small mb-2">
                            { (item.notificationType === "post" || item?.notificationType == "follower" ) ? item.title :
                                item.notificationType === "session" ? (item.json && JSON.parse(item.body).mentor === user?._id ? `New Session has been booked by ${(item.user !== null ? item.user.firstName : "")} ${(item.user !== null ? item.user.lastName : "")}` : `You have booked a new session with ${(item.user !== null ? item.user.firstName : "")} ${(item.user !== null ? item.user.lastName : "")}`) :
                                    item.notificationType === "session-update" ? (item.json && JSON.parse(item.body).status === "cancelled" ? `Your session on ${JSON.parse(item.body).skill} has been cancelled` : item.title) :
                                        (item.json && JSON.parse(item.body).message)}
                        </p>
                        <p className="small ms-3 text-nowrap">
                            {calculateTimeAgo(item.minutes)}
                        </p>
                    </div>
                    {item.notificationType === "session" ? (
                        item.json && JSON.parse(item.body).mentor === user?._id && (JSON.parse(item.body).status?.toLowerCase() === "pending") ? (
                            isAcceptRejectButtonClicked ? "" :
                                <div className="d-flex">
                                    <button onClick={async () => {
                                        handleAcceptRejectButtonClicked(index, "Accepted");
                                        handleSubmitMessage(JSON.stringify({
                                            status: "Accepted",
                                            id: JSON.parse(item.body).sessionId,
                                            mentor: JSON.parse(item.body).mentor,
                                            mentee: JSON.parse(item.body).bookedby,
                                            topic: JSON.parse(item.body).skill,
                                            duration: 30,
                                            type: 1,
                                            agenda: "Accept Notification",
                                            socketId: await getSocketId(JSON.parse(item.body).bookedby),
                                            hostemail: user?.email,
                                            sessionDate: JSON.parse(item.body).date,
                                            messageId: JSON.parse(item.body)?.messageId,
                                        }), JSON.parse(item.body).bookedby, item._id);
                                    }} className="btn btn-primary-soft btn-sm mb-0 me-2">
                                        Accept
                                    </button>
                                    <a style={{ cursor: "pointer" }} onClick={() => {
                                        //setIsAcceptRejectButtonClicked(false);
                                        openReject(JSON.parse(item.body), item._id, index);
                                    }} className="btn-sm reject-option">
                                        Decline
                                    </a>
                                </div>
                        ) : (
                            <div style={{ textTransform: "capitalize", color: "purple" }}>
                                {item.json && (JSON.parse(item.body).status === "Rejected" ? "Declined" : JSON.parse(item.body).status)}
                            </div>
                        )
                    ) : ("")}
                </div>
            </div>
        </li>
    );
};

export default React.memo(NotificationListItem);
