import React, { lazy, Suspense, useEffect, useState } from "react";
import { HashRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, Slide, toast } from "react-toastify";
import useUser from "./hooks/useUser";
import Header from '../src/Component/Header';
import 'react-toastify/dist/ReactToastify.css';


/* const Article = lazy(() => import("./Pages/Article/Article")); //chnage
const ArticleList = lazy(() => import("./Pages/ArticleList/ArticleList")); //chnage
const ArticleDetails = lazy(() => import("./Pages/Component/ArticleDetails/ArticleDetails")); //chnage
const TermCondition = lazy(() => import("./Pages/Component/TermCondition/TermCondition")); //change
const Home = lazy(() => import("./Pages/Home")); // change
const PrivacyPolicy = lazy(() => import("./Pages/Component/PrivacyPolicy/PrivacyPolicy")); //change
const FaqDetails = lazy(() => import("./Pages/Component/Faq/FaqDetails"));//change
const Cookiepolicy = lazy(() => import("./Pages/Component/TermCondition/Cookiepolicy"));
const SearchArticleList = lazy(() => import("./Pages/ArticleList/SearchArticleList")); */

import Article from "./Pages/Article/Article";
import ArticleList from "./Pages/ArticleList/ArticleList";
import ArticleDetails from "./Pages/Component/ArticleDetails/ArticleDetails";
import TermCondition from "./Pages/Component/TermCondition/TermCondition";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/Component/PrivacyPolicy/PrivacyPolicy";
import FaqDetails from "./Pages/Component/Faq/FaqDetails";
import Cookiepolicy from "./Pages/Component/TermCondition/Cookiepolicy";
import SearchArticleList from "./Pages/ArticleList/SearchArticleList";
import UserProfile from "./userInterface/userProfile/UserProfile";
import UserPost from "./userInterface/UserHome/Components/UserPost";
import ScrollToTop from "./Component/ScrollToTop";
import GroupSessionDetails from "./Pages/Session/Component/groupSession-learner/GroupSessionDetails";



const Login = lazy(() => import("./On_Bording/UserLogin"));
const MyProfile = lazy(() => import("./userInterface/MyProfile/MyProfile"));
const Setting = lazy(() => import("./userInterface/Setting/Setting"));
const UserHome = lazy(() => import("./userInterface/UserHome/UserHome"));
const Booking = lazy(() => import("./Pages/Booking"));
const Mentor = lazy(() => import("./Pages/Component/Booking/Mentor"));
const Dummy = lazy(() => import("./On_Bording/steps/Dummy"));
const Step1 = lazy(() => import("./On_Bording/steps/Step1"));
const Step2 = lazy(() => import("./On_Bording/steps/Step2"));
const Step3 = lazy(() => import("./On_Bording/steps/Step3"));
const Step4 = lazy(() => import("./On_Bording/steps/Step4"));
const Step4_1 = lazy(() => import("./On_Bording/steps/Step4_1"));
const Step5 = lazy(() => import("./On_Bording/steps/Step5"));
const Step6 = lazy(() => import("./On_Bording/steps/Step6"));
const Step7 = lazy(() => import("./On_Bording/steps/Step7"));
const Step8 = lazy(() => import("./On_Bording/steps/Step8"));
const Step9 = lazy(() => import("./On_Bording/steps/Step9"));
const Confirmation = lazy(() => import("./User/Confirmation/Confirmation"));
const DateTime = lazy(() => import("./User/DateTime/DateTime"));

const SearchResult = lazy(() => import("./userInterface/common-component/SearchResult"));
const MyPost = lazy(() => import("./userInterface/MyProfile/Component/MyPost"));
const MyFavourite = lazy(() => import("./userInterface/MyProfile/Component/MyFavourite"));
const MyMedia = lazy(() => import("./userInterface/MyProfile/Component/MyMedia"));
const MyVideos = lazy(() => import("./userInterface/MyProfile/Component/MyVideos"));
const Session = lazy(() => import("./Pages/Session/Session"));
// const GroupSessionDetails = lazy(() => import("./Pages/Session/Component/groupSession-learner/GroupSessionDetails"));
const GruopSession = lazy(() => import("./Pages/Session/Component/groupSession-learner/GroupSession"));
const MySession = lazy(() => import("./Pages/mysession/MySession"));
const MessageLearner = lazy(() => import("./Pages/Messages/MessageLearner"));
const MessageMentor = lazy(() => import("./Pages/Messages/MessageMentor"));
const Notification = lazy(() => import("./Pages/Notification/Notification"));
const Learner = lazy(() => import("./ProfileForLearner/Learner"));
const Payment = lazy(() => import("./User/DateTime/Payment"));
const PaymentConfirm = lazy(() => import("./User/DateTime/PaymentConfirm"));
const MySettings = lazy(() => import("./userInterface/MyProfile/Component/MySettings"));
const ConnectionsList = lazy(() => import("./userInterface/UserHome/Components/ConnectionsList"));
const SupportNew = lazy(() => import("./Pages/SupportNew/SupportNew"));
const CaseInfo = lazy(() => import("./Pages/SupportNew/CaseInfo"));
const AddCase = lazy(() => import("./Pages/SupportNew/AddCase"));
const ZoomVideo = lazy(() => import("./Pages/ZoomVideo/ZoomVideo"));
const ZoomVideoMessage = lazy(() => import("./Pages/ZoomVideo/ZoomVideoMessage"));
const PaymentFail = lazy(() => import("./User/Confirmation/PaymentFail"));
const ZoomHome = lazy(() => import("./Pages/ZoomVideo/ZoomHome"));
const GroupSessionTopicDetails = lazy(() => import("./Pages/Session/Component/GroupSessionTopicDetails"));
const IntermediateLogin = lazy(() => import("./Pages/IntermediateLogin"));
const EnrollmentConfirmedPage = lazy(() => import("./Pages/Session/Component/groupSession-learner/EnrollmentConfirmedPage"));
const ManteeTopicDetail = lazy(() => import("./Pages/Session/Component/ManteeTopicDetail"));


// return new component with Common Header
// HOC for Navbar

const NavbarComponent = ({ component: Component, ...rest }: LoadComponentProps) => {
  // const { user } = useUser();
  const userToken = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  useEffect(() => {
    if (!userToken) {
      //toast.error('Please login first');
      navigate('/');
    }
  }, [])

  return (
    <>
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Component {...rest} />
      </Suspense>
      <ScrollToTop />
    </>
  )
};

const WithoutHeaderSuspenseComponent = ({ component: Component, ...rest }: LoadComponentProps) => {
  // const { user } = useUser();
  const userToken = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
 /*  useEffect(() => {
    if (!userToken) {
      //toast.error('Please login first');
      navigate('/');
    }
  }, []) */

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Component {...rest} />
      </Suspense>
      <ScrollToTop />
    </>
  )
};


function App() {
  const [searchData, setSearchData] = useState([]);
  const [mentorPrice, setMPrice] = useState();

  return (
    <>
      {/* */}
      <ToastContainer
        transition={Slide}
        position="top-center"
      // autoClose={3000}
      // hideProgressBar={false}
      // newestOnTop={true}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      // limit={1}
      />

      <Routes>
        {/* Public Routes */}
        <Route path="" element={<Home />} />
        <Route path="term-and-condtion" element={<TermCondition />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cookie-policy" element={<Cookiepolicy />} />
        <Route path="faqs" element={<FaqDetails />} />
        <Route path="article" element={<Article />} />
        <Route path="articles" element={<SearchArticleList />} />
        <Route path="articleList/:id" element={<ArticleList />} />
        <Route path="articledetails/:id" element={<ArticleDetails />} />
        <Route path="userprofile/:userid" element={<UserProfile setSearchData={setSearchData} setMPrice={setMPrice} />} />
        <Route path="userpost/:postid" element={<UserPost setSearchData={setSearchData} />} />
        <Route path="groupsessiondetails/:sessionId" element={<GroupSessionDetails setSearchData={setSearchData} />} />


        {/* Protected Routes */}
        <Route path="payment" element={<NavbarComponent component={Payment} setSearchData={setSearchData} setMPrice={setMPrice} />} />
        <Route path="payment-confirm" element={<NavbarComponent component={PaymentConfirm} />} />
        <Route path="Booking" element={<NavbarComponent component={Booking} />} />
        <Route path="login" element={<NavbarComponent component={IntermediateLogin} />} />
        <Route path="Mentor" element={<NavbarComponent component={Mentor} />} />
        <Route path="signup" element={<WithoutHeaderSuspenseComponent component={Step1} />} />
        <Route path="dummy" element={<WithoutHeaderSuspenseComponent component={Dummy} />} />
        <Route path="email" element={<WithoutHeaderSuspenseComponent component={Step2} />} />
        <Route path="address" element={<WithoutHeaderSuspenseComponent component={Step3} />} />
        <Route path="employement" element={<WithoutHeaderSuspenseComponent component={Step4} />} />
        <Route path="education" element={<WithoutHeaderSuspenseComponent component={Step4_1} />} />
        <Route path="otp-verification" element={<WithoutHeaderSuspenseComponent component={Step5} />} />
        <Route path="mentee-topics" element={<WithoutHeaderSuspenseComponent component={Step6} />} />
        <Route path="mentor-topics" element={<WithoutHeaderSuspenseComponent component={Step7} />} />
        <Route path="add-connection" element={<WithoutHeaderSuspenseComponent component={Step8} />} />
        <Route path="add-connection-mentor" element={<WithoutHeaderSuspenseComponent component={Step9} />} />
        <Route path="datetime" element={<NavbarComponent component={DateTime} setSearchData={setSearchData} />} />
        <Route path="confirm/:mento_id" element={<NavbarComponent component={Confirmation} />} />
        <Route path="failed" element={<NavbarComponent component={PaymentFail} />} />
        <Route path="userlogin" element={<NavbarComponent component={Login} />} />
        <Route path="video-call/:sessionId" element={<WithoutHeaderSuspenseComponent component={ZoomVideo} />} />
        <Route path="video-message" element={<WithoutHeaderSuspenseComponent component={ZoomVideoMessage} />} />
        <Route path="zoom-home" element={<NavbarComponent component={ZoomHome} />} />
        <Route path="mypost" element={<NavbarComponent component={MyPost} setSearchData={setSearchData} />} />
        <Route path="myfavourite" element={<NavbarComponent component={MyFavourite} />} />
        <Route path="mymedia" element={<NavbarComponent component={MyMedia} />} />
        <Route path="myvideo" element={<NavbarComponent component={MyVideos} />} />
        <Route path="home-page" element={<NavbarComponent component={UserHome} setSearchData={setSearchData} setMPrice={setMPrice} />} />
        <Route path="setting" element={<NavbarComponent component={Setting} setSearchData={setSearchData} />} />
        <Route path="session" element={<NavbarComponent component={Session} setSearchData={setSearchData} />} />
        <Route path="mysession" element={<NavbarComponent component={MySession} />} />
        <Route path="messagelearner/:user_id" element={<NavbarComponent component={MessageLearner} setSearchData={setSearchData} />} />
        <Route path="messagelearner" element={<NavbarComponent component={MessageLearner} setSearchData={setSearchData} />} />
        <Route path="messagementor" element={<NavbarComponent component={MessageMentor} setSearchData={setSearchData} />} />
        <Route path="notification" element={<NavbarComponent component={Notification} />} />
        {/* <Route path="groupsessiondetails/:sessionId" element={<NavbarComponent component={GroupSessionDetails} />} /> */}
        <Route path="/groupsessions/topics/details/:topicId" element={<NavbarComponent component={GroupSessionTopicDetails} />} />
        <Route path="/groupsessions/topics/session/:topicId" element={<NavbarComponent component={ManteeTopicDetail} />} />
        <Route path="groupsession" element={<NavbarComponent component={GruopSession} />} />
        <Route path="enrollment-confirmed" element={<NavbarComponent component={EnrollmentConfirmedPage} />} />
        {/* <Route path="myprofile" element={<UserProfile />} /> */}
        <Route path="myprofile" element={<NavbarComponent component={MyProfile} setSearchData={setSearchData} setMPrice={setMPrice} />} />
        <Route path="search-Result" element={<NavbarComponent component={SearchResult} searchData={searchData} setSearchData={setSearchData} setMPrice={setMPrice} mentorPrice={mentorPrice} />} />
        <Route path="Learner" element={<NavbarComponent component={Learner} setSearchData={setSearchData} />} />
        <Route path="connections" element={<NavbarComponent component={ConnectionsList} setSearchData={setSearchData} />} />

        <Route path="mysettings" element={<NavbarComponent component={MySettings} setSearchData={setSearchData} />} />
        {/* Support New */}
        <Route path="/support-new" element={<NavbarComponent component={SupportNew} setSearchData={setSearchData} setMPrice={setMPrice} />} />
        <Route path="/support-new/add-new-case" element={<NavbarComponent component={AddCase} setSearchData={setSearchData} setMPrice={setMPrice} />} />
        <Route path={`/support-new/:case_id`} element={<NavbarComponent component={CaseInfo} setSearchData={setSearchData} setMPrice={setMPrice} />} />


      </Routes >

    </>
  );
}

export default App;
