import React from "react";
import { Link } from "react-router-dom";

const GroupDetailHero = ({topicName}) => {
  return (
    <section className="pt-5 position-relative groupsessiondetail">
      <div className="bg-overlay  opacity-8"></div>

      <div className="container mt-5">
        <div className="py-5 card">
          <div className="row position-relative">
            <div className="col-lg-10 mx-auto">
              <nav className="nav nav-divider justify-content-center  pt-2 small mb-4">
                <Link className="nav-link  fs-6" to="/home-page">
                  Home
                </Link>
                <span className="fs-6 mx-2"> &gt; </span>
              <Link to="/groupSession"className="nav-link  fs-6">
                  All Group Sessions
                </Link>
                <span className=" fs-6 mx-2"> &gt; </span>
                <Link className="nav-link  fs-6 active-session" to="/groupsessiondetails" > 
                  Group Session Detail
                </Link>
              </nav>
              <div className="text-center">
                <h1 className=" mt-2 fs-2">
                {topicName}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GroupDetailHero;
