import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const IMAGE_URL = "http://localhost:3001";

const RecomendedCard = () => {
  const [category, setCategory] = useState([]);

  const getCategory = async () => {
    const data = await axios.get(`${BASE_URL}/v1/Topics/AllTopic?keyword=`);
    console.log(data);
    setCategory(data.data.data);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          {category.map((val, key) => {
            return (
              <div key={val._id} className="col-lg-4 col-md-6">
                <div class=" card_box mt-3">
                  <Link to={`/articleList/${val._id}`}>
                    <div style={{ textAlign: "center" }}>
                      {val.topicImage?.path ? (
                        <LazyLoadImage
                          src={`${BASE_URL}/${val.topicImage?.filename}`}
                          alt="google-upload" className="d-flex m-auto mb-3"
                          style={{ maxHeight: "40px", width: "48px" }}
                           crossorigin="anonymous"
                        />
                      ) : (
                        <LazyLoadImage src="/assets/images/article.png"
                          style={{ maxHeight: "40px", width: "48px" }}
                          alt="google-upload" className="d-flex m-auto mb-3"  />
                      )}
                      <h5 class="heading5 ancor_color text-center mb-0">
                        {val.topicName}
                      </h5>
                    </div>
                    <span class="link"></span>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RecomendedCard;
