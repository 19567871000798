import React, { useState } from "react";
import { useEffect } from "react";
import Rating from "@mui/material/Rating";
function Skills(props) {
  const [userSkill, setUserSkill] = useState([]);
  const [skills,setSkills]=useState([])
  let userProfileData = JSON.parse(localStorage.getItem("userBookingData"));
  useEffect(() => {
    setSkills(userProfileData.skills)
    setUserSkill(props.userData.data.menteeTopics);
  }, []);

  return (
    <>
      <div className="col-sm-6 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between border-0">
          <h5 className="card-title mt-0 mb-0 text-purple">Skills or topics I can mentor</h5>
          </div>

          <div className="card-body position-relative pt-0">
            {skills.map((val, key) => {
              return (
                <>
                <div className="d-flex justify-content-between align-items-center d-block mb-2" key={key}>
                  {/* <!-- Info --> */}
                  <div>
                    <h6 className="card-title mb-0">
                    {val.title}
                    </h6>
                  </div>
                  {/* <small>
                  <ul className="d-flex list-unstyled mb-2">
                    <li>
                      <i className={`fa-solid fa-star  ${val.rating>=1?"text-warning":"text-muted"}`}></i>
                    </li>
                    <li>
                      <i className={`fa-solid fa-star  ${val.rating>=2?"text-warning":"text-muted"}`}></i>
                    </li>
                    <li>
                      <i className={`fa-solid fa-star  ${val.rating>=3?"text-warning":"text-muted"}`}></i>
                    </li>
                    <li>
                      <i className={`fa-solid fa-star  ${val.rating>=4?"text-warning":"text-muted"}`}></i>
                    </li>
                    <li>
                      <i className={`fa-solid fa-star  ${val.rating>=5?"text-warning":"text-muted"}`}></i>
                    </li>
                  </ul>
                  </small> */}
                  <small>
                    <ul className="d-flex list-unstyled mb-2" style={{ 'pointerEvents': 'none' }}>
                      <li>
                        <Rating
                          name="simple-controlled"
                          value={val.rating}
                        />
                      </li>

                    </ul>
                  </small>
                  
                </div>
                <div>
                <h6 className="mb-0">
               <p className="skill-description"> {val.description} </p>
             </h6>
                </div>
                </>
              );
            })}
          </div>
          {/* <!-- Card body END --> */}
        </div>
      </div>
    </>
  );
}

export default Skills;
