import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

function Navbar({ handleClick }) {
    const [show, setShow] = useState(false)
    const handleShow = () => {
        if (show) {
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    return (
        <>
            {/* <!-- header section start  --> */}
            <header>
                <nav className="navbar navbar-expand-lg navbar-light home-navbar  fixed-top" id="header_frame">
                    <div className="container-fluid header_section-home mt-2">


                        <Link className="navbar-brand header-brand header-brand-img-responsive" to="/">
                           {/*  <img src="/assets/images/logo/logo_home.png" /> */}
                            <LazyLoadImage  src="/assets/images/logo/logo_home.png" alt="logo" />
                        </Link>
                        <button className={show ? "navbar-toggler navbar-toggler-align-right " : "navbar-toggler navbar-toggler-align-right collapsed "} type="button" onClick={handleShow} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={show ? "false" : "true"} aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>


                        <div className={"collapse navbar-collapse show"} style={{ display: show ? "block" : "none" }} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mynavbar">
                                <li className="nav-item border-bottom-none">
                                    {/* <Link className="nav-link" to="#groupsession">
                                    Upcoming Group Sessions
                                </Link> */}
                                </li>
                                <li className="nav-item border-bottom-none">
                                    {/* <Link className="nav-link" to="/">
                                    Find People
                                </Link> */}
                                </li>
                                <li className="nav-item border-bottom-none">
                                    <Link className="nav-link" to="/article">
                                        Help Center
                                    </Link>
                                </li>
                                <li className="nav-item border-bottom-none">
                                    <Link className="nav-link" to="/faqs">
                                        FAQs
                                    </Link>
                                </li>
                                <li className="nav-item border-bottom-none">
                                    <a className="nav-link btnnav" style={{ cursor: 'pointer' }} onClick={handleClick}>Connect with Linkedin</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default React.memo(Navbar)