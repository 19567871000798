import React, { useState, useEffect } from "react";
import { updateCompanyInfo } from "../../../Services/AdminProfileSeervice";
import useUser from "../../../hooks/useUser";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="text" style={{ maxWidth: "90%" }}>
      {isReadMore && text?.length > 220 ? text?.slice(0, 220) : text}
      {text?.length > 250 && (
        <span
          onClick={toggleReadMore}
          className="read-or-hide"
          style={{ cursor: "pointer" }}
        >
          {isReadMore ? "...show more" : " show less"}
        </span>
      )}
    </p>
  );
};

const AdminProfileInfo = ({ userData }) => {
  const { user, updateUserField,updateMultipleFields } = useUser();
  const [overview, setOverview] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [headQuarters, setHeadQuarters] = useState("");
  const [founded, setFounded] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [onlySeenMode, setOnlySeenMode] = useState(false);

  useEffect(() => {
    if (userData && userData?.data?.companyInfo) {
      setOnlySeenMode(true);
      const latestCompanyInfo =
        userData?.data?.companyInfo[userData?.data?.companyInfo.length - 1];
      setOverview(latestCompanyInfo?.overview);
      setEmail(latestCompanyInfo?.email);
      setWebsite(latestCompanyInfo?.website);
      setHeadQuarters(latestCompanyInfo?.headQuarters);
      setFounded(latestCompanyInfo?.founded);
    }
  }, [userData]);

  useEffect(() => {
    if (user && user.companyInfo) {
      const latestCompanyInfo = user.companyInfo;

      setOverview(latestCompanyInfo?.overview);
      setEmail(latestCompanyInfo?.email);
      setWebsite(latestCompanyInfo?.website);
      setHeadQuarters(latestCompanyInfo?.headQuarters);
      setFounded(latestCompanyInfo?.founded);
    }
  }, [user]);

  const handleEdit = (section, value) => {
    setCurrentSection(section);
    setCurrentValue(value);
  };

  const handleSave = async () => {
    const updatedData = {
      overview,
      email,
      website,
      headQuarters,
      founded,
    };

    // Update the specific field
    updatedData[currentSection] = currentValue;

    // Call the API to update the data
    try {
      // Update the state based on the edited section
      switch (currentSection) {
        case "overview":
          setOverview(currentValue);
          break;
        case "email":
          setEmail(currentValue);
          break;
        case "website":
          setWebsite(currentValue);
          break;
        case "headQuarters":
          setHeadQuarters(currentValue);
          break;
        case "founded":
          setFounded(currentValue);
          break;
        default:
          break;
      }

      const response = await updateCompanyInfo(user?._id, updatedData);
      if (response.status === 200) {
         /// Update the user context with the new data it is store in the companyInfo array 
         updateMultipleFields("companyInfo", updatedData);  
      }
    } catch (error) {
      console.error("Error updating profile information:", error);
    }

    setCurrentSection("");
  };

  return (
    <>
      <div className="card">
        {/* Overview */}
        <div className="card-header d-flex justify-content-between border-0 pb-0">
          <h5 className="card-title mt-0 mb-0 text-purple">Overview</h5>
        </div>
        <div
          className="card-body"
          style={{ padding: "0.25rem 1.25rem 1.25rem 1.25rem" }}
        >
          <div className="rounded pe-3 mb-3">
            <div className="d-flex align-items-center justify-content-between edit-button">
              <h5></h5>
              {onlySeenMode ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn-primary-soft btn-xs btn_edit ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#modalEditInfo"
                  onClick={() => handleEdit("overview", overview)}
                >
                  <i className="bi bi-pencil-fill pe-1"></i> Edit
                </button>
              )}
            </div>
            {overview === null || overview === "" ? (
              <p>
                You can write about your work experience, industry, or skill
                set. Professionals also talk about their key achievements or
                previous job experiences.
              </p>
            ) : (
              <ReadMore>{overview}</ReadMore>
            )}
          </div>
        </div>

        {/* Website */}
        <div className="card-header d-flex justify-content-between border-0 py-0">
          <h5 className="card-title mt-0 mb-0 text-purple">Website</h5>
        </div>
        <div
          className="card-body"
          style={{ padding: "0.25rem 1.25rem 1.25rem 1.25rem" }}
        >
          <div className="rounded pe-3 mb-3">
            <div className="d-flex align-items-center justify-content-between edit-button">
              <h5></h5>
              {onlySeenMode ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn-primary-soft btn-xs btn_edit ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#modalEditInfo"
                  onClick={() => handleEdit("website", website)}
                >
                  <i className="bi bi-pencil-fill pe-1"></i> Edit
                </button>
              )}
            </div>
            {website === null || website === "" ? (
              <p>Please add your website</p>
            ) : (
              <ReadMore>{website}</ReadMore>
            )}
          </div>
        </div>

        {/* Email */}
        <div className="card-header d-flex justify-content-between border-0 py-0">
          <h5 className="card-title mt-0 mb-0 text-purple">Email</h5>
        </div>
        <div
          className="card-body"
          style={{ padding: "0.25rem 1.25rem 1.25rem 1.25rem" }}
        >
          <div className="rounded pe-3 mb-3">
            <div className="d-flex align-items-center justify-content-between edit-button">
              <h5></h5>
              {onlySeenMode ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn-primary-soft btn-xs btn_edit ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#modalEditInfo"
                  onClick={() => handleEdit("email", email)}
                >
                  <i className="bi bi-pencil-fill pe-1"></i> Edit
                </button>
              )}
            </div>
            {email === null || email === "" ? (
              <p>Please add your email</p>
            ) : (
              <ReadMore>{email}</ReadMore>
            )}
          </div>
        </div>

        {/* Headquarters */}
        <div className="card-header d-flex justify-content-between border-0 py-0">
          <h5 className="card-title mt-0 mb-0 text-purple">Headquarters</h5>
        </div>
        <div
          className="card-body"
          style={{ padding: "0.25rem 1.25rem 1.25rem 1.25rem" }}
        >
          <div className="rounded pe-3 mb-3">
            <div className="d-flex align-items-center justify-content-between edit-button">
              <h5></h5>
              { 
                onlySeenMode ? '' :
              <button
                className="btn btn-sm btn-primary-soft btn-xs btn_edit ms-2"
                data-bs-toggle="modal"
                data-bs-target="#modalEditInfo"
                onClick={() => handleEdit("headQuarters", headQuarters)}
              >
                <i className="bi bi-pencil-fill pe-1"></i> Edit
              </button>}
            </div>
            {headQuarters === null || headQuarters === "" ? (
              <p>Please add your headquarters</p>
            ) : (
              <ReadMore>{headQuarters}</ReadMore>
            )}
          </div>
        </div>

        {/* Founded */}
        <div className="card-header d-flex justify-content-between border-0 py-0">
          <h5 className="card-title mt-0 mb-0 text-purple">Founded</h5>
        </div>
        <div
          className="card-body"
          style={{ padding: "0.25rem 1.25rem 1.25rem 1.25rem" }}
        >
          <div className="rounded pe-3 mb-3">
            <div className="d-flex align-items-center justify-content-between edit-button">
              <h5></h5>
              { 
                onlySeenMode ? '' :  <button
                className="btn btn-sm btn-primary-soft btn-xs btn_edit ms-2"
                data-bs-toggle="modal"
                data-bs-target="#modalEditInfo"
                onClick={() => handleEdit("founded", founded)}
              >
                <i className="bi bi-pencil-fill pe-1"></i> Edit
              </button>}
            </div>
            {founded === null || founded === "" ? (
              <p>Please add the year your company was founded</p>
            ) : (
              <ReadMore>{founded}</ReadMore>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="modalEditInfo"
        tabIndex="-1"
        aria-labelledby="modalEditInfoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalEditInfoLabel">
                Edit {currentSection}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                className="form-control"
                value={currentValue}
                onChange={(e) => setCurrentValue(e.target.value)}
                rows="4"
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSave}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProfileInfo;
