const Mentor_List=[
    {
        iconC:"",
        heading:"Build your brand",
        text:"Build your brand and get known to others as a mentor, trainer or a coach in a topic, skill, or industry."
    },
    {
        iconC:"card_icon2",
        heading:"Help others succeed",
        text:"Help other professionals become more successful by leveraging your knowledge, skills, and expertise."
    },
    {
        iconC:"card_icon3",
        heading:"A rewarding side hustle",
        text:"A side hustle that lets you grow your professional network, helps you develop others, rewards you  for your time and efforts."
    },
    {
        iconC:"card_icon4",
        heading:"Enhance your knowledge",
        text:"Stay relevant and updated in today's hyper changing world of work by learning what others are looking to learn."
    },
]

export default Mentor_List;