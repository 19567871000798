import React, { useEffect, useState, useCallback } from "react";
//import UserNavbar from "../../../userInterface/UserNavbar";
import Header from "../../../Component/Header";
import { userProfile } from "../../../Services/UserAuth";
import Navbar from "../Navbar";
import Footer from "../Footer";
import CommenHero from "../HeroHeading/CommenHero";
import ReactHtmlParser from "react-html-parser";
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PrivacyPolicy = ({ setMPrice, setSearchData }) => {
    const navigate = useNavigate();
    let headings = {
        n1: "Privacy",
        n2: "Pol",
        n3: "icy",
    };

    let html = `<p align="center"><strong>Privacy Policy</strong></p>
          <p><strong><span>Table of Contents</span></strong></p>
                      <ul>
                          <li>
                              <p><span>Data We Collect</span></p>
                          </li>
                          <li>
                              <p><span>How We Use Your Data</span></p>
                          </li>
                          <li>
                              <p><span>How We Share Information</span></p>
                          </li>
                          <li>
                              <p><span>Your Choices and Obligations</span></p>
                          </li>
                          <li>
                              <p><span>Other Important Information</span></p>
                          </li>
                      </ul>
          <p><strong><span>Introduction</span></strong></p>
          <p><span>We (Learnet LLC, Learnet.ai) are a social networking and online learning platform for people. Members use our Services to find and be found for mentoring or learning opportunities, to connect with others and find information. Our Privacy Policy applies to any Member or Visitors.</span></p>
          <p><span>Our registered users (&ldquo;Members&rdquo;) share their professional identities, engage with their network, exchange knowledge and professional insights, post and view relevant content, learn and develop skills, and meet over video calls. Content and data on some of our Services is viewable to non-members (&ldquo;Visitors&rdquo;).</span></p>
          <p><span>We use the term &ldquo;Designated Countries&rdquo; to refer to countries in the European Union (EU), European Economic Area (EEA), and Switzerland.</span></p>
          <p><strong><span>Services</span></strong></p>
          <p><span>This Privacy Policy, including our Cookie Policy, applies to your use of our Services.</span></p>
          <p><span>This Privacy Policy applies to Services offered by Learnet LLC, Learnet.ai, video sessions, chats, messages, communications and other services but excluding services that state that they are offered under a different privacy policy. For California residents, additional disclosures required by California law may be found in our California Privacy Disclosure at the end of the page.</span></p>
          <p><strong><span>Data Controllers and Contracting Parties</span></strong></p>
          <p><span>Learnet LLC will be the controller of your personal data provided to, or collected by or for, or processed in connection with, our Services.</span></p>
          <p><span>As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.</span></p>
          <p><strong><span>Change</span></strong></p>
          <p><span>Changes to the Privacy Policy apply to your use of our Services after the &ldquo;effective date.&rdquo;</span></p>
          <p><span>Learnet (&ldquo;we&rdquo; or &ldquo;us&rdquo;) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.</span></p>
          <p><span>You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.</span></p>
          <p><strong><span>2. Data We Collect</span></strong></p>
          <p><strong><span>2.1 Data You Provide To Us</span></strong></p>
          <p><span>You provide data to create an account with us.</span></p>
          <p><strong><span>Registration</span></strong></p>
          <p><span>To create an account you need to provide your Linkedin data including your email address and password. Learnet connects with your Linkedin profile and retrieves limited information such as your name, email, and / or mobile number to create a Learnet account. If you enroll as a mentor or a learner to participate in video sessions, you will need to provide payment (e.g., credit card) and billing information.</span></p>
          <p><span>You create your Learnet.ai profile (a complete profile helps you get the most from our Services).</span></p>
          <p><strong><span>Profile</span></strong></p>
          <p><span>You have choices about the information on your profile, such as your education, work experience, skills, photo, city or area. You don&rsquo;t have to provide additional information on your profile; however, profile information helps you to get more from our Services, including helping mentors or learners find you. It&rsquo;s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available.</span></p>
          <p><span>You give other data to us, such as by syncing your address book or calendar.</span></p>
          <p><strong><span>Posting and Uploading</span></strong></p>
          <p><span>We collect personal data from you when you provide, post or upload it to our Services. If you opt to import your address book, we receive your contacts (including contact information your service provider(s) or app automatically added to your address book when you communicated with addresses or numbers not already in your list).</span></p>
          <p><span>If you sync your contacts or calendars with our Services, we will collect your address book and calendar meeting information to keep growing your network by suggesting connections for you and others, and by providing information about events, e.g. times, places, attendees and contacts.</span></p>
          <p><span>You don&rsquo;t have to post or upload personal data; though if you don&rsquo;t, it may limit your ability to grow and engage with your network over our Services.</span></p>
          <p><strong><span>2.2 Data From Others</span></strong></p>
          <p><span>Others may post or write about you.</span></p>
          <p><strong><span>Content and News</span></strong></p>
          <p><span>You and others may post content that includes information about you (as part of articles, posts, comments, videos) on our Services. We also may collect public information about you, such as professional-related news and accomplishments, and make it available as part of our Services, including, as permitted by your settings, in notifications to others of mentions in the news.</span></p>
          <p><span>Others may sync their contacts or calendar with our Services</span></p>
          <p><strong><span>Contact and Calendar Information</span></strong></p>
          <p><span>We receive personal data (including contact information) about you when others import or sync their contacts or calendar with our Services, associate their contacts with Member profiles, scan and upload business cards, or send messages using our Services (including invites or connection requests). If you or others opt-in to sync email accounts with our Services, we will also collect &ldquo;email header&rdquo; information that we can associate with Member profiles.&nbsp;</span></p>
          <p><strong><span>2.3 Service Use</span></strong></p>
          <p><span>We log your visits and use of our Services, including mobile apps.</span></p>
          <p><span>We log usage data when you visit or otherwise use our Services, including our sites, app and platform technology, such as when you view or click on content (e.g. video links) or ads (on or off our sites and apps), perform a search, install or update one of our mobile apps, share articles or take learning sessions over videos. We use log-ins, cookies, device information and internet protocol (&ldquo;IP&rdquo;) addresses to identify you and log your use.</span></p>
          <p><strong><span>2.4 Cookies and Similar Technologies</span></strong></p>
          <p><span>We collect data through cookies and similar technologies.</span></p>
          <p><span>As further described in our Cookie Policy, we use cookies and similar technologies (e.g., pixels and ad tags) to collect data (e.g., device IDs) to recognize you and your device(s) on, off and across different services and devices where you have engaged with our Services. We also allow some others to use cookies as described in our Cookie Policy. If you are outside the Designated Countries, we also collect (or rely on others who collect) information about your device where you have not engaged with our Services (e.g., ad ID, IP address, operating system and browser information) so we can provide our Members with relevant ads and better understand their effectiveness. You can opt out from our use of data from cookies and similar technologies that track your behavior on the sites of others for ad targeting and other ad-related purposes.</span></p>
          <p><strong><span>2.5 Your Device and Location</span></strong></p>
          <p><span>We receive data through cookies and similar technologies</span></p>
          <p><span>When you visit or leave our Services (including some plugins and our cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to and the time of your visit. We also get information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location.</span></p>
          <p><strong><span>2.6 Messages</span></strong></p>
          <p><span>If you communicate through our Services, we learn about that.</span></p>
          <p><span>We collect information about you when you send, receive, or engage with messages in connection with our Services. We also use automatic scanning technology on messages to support and protect our site. For example, we use this technology to suggest possible responses to messages and to manage or block content that violates our Terms of Use.</span></p>
          <p><strong><span>2.7 Sites and Services of Others</span></strong></p>
          <p><span>We get data when you visit sites that include our ads, cookies or some of our plugins or when you log-in to others&rsquo; services with your Learnet account.</span></p>
          <p><span>We receive information about your visits and interaction with services provided by others when you log-in with Learnet or visit others&rsquo; services that include some of our plugins or our ads, cookies or similar technologies.</span></p>
          <p><strong><span>2.8 Other</span></strong></p>
          <p><span>We are improving our Services, which means we get new data and create new ways to use data.</span></p>
          <p><span>Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we collect, use or share your data, we will notify you and may also modify this Privacy Policy.</span></p>
          <p><br></p>
          <p><strong><span>3. How We Use Your Data</span></strong></p>
          <p><span>We use your data to provide, support, personalize and develop our Services.</span></p>
          <p><span>How we use your personal data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide and personalize our Services, including with the help of automated systems and inferences we make, so that our Services (including ads) can be more relevant and useful to you and others.</span></p>
          <p><strong><span>3.1 Services</span></strong></p>
          <p><span>Our Services help you connect with others, find and be found for mentoring or learning opportunities, stay informed, get training and be more productive.</span></p>
          <p><span>We use your data to authorize access to our Services and honor your settings.</span></p>
          <p><span>Stay Connected</span></p>
          <p><span>Our Services allow you to stay in touch and up to date with colleagues, partners, clients, and other professional contacts. To do so, you can &ldquo;follow&rdquo; the members who you choose, and otherMembers can follow you too. Subject to your and their settings, when you follow other Members, you will be able to search each others&rsquo; followers in order to exchange professional opportunities.</span></p>
          <p><span>We use data about you (such as your profile, profiles you have viewed or data provided through address book uploads or partner integrations) to help others find your profile, suggest connections for you and others (e.g. Members who share your contacts or job experiences) and enable you to invite others to become a Member and connect with you. You can also opt-in to allow us to use your precise location or proximity to others for certain tasks (e.g. to suggest other nearby Members for you to follow).</span></p>
          <p><span>It is your choice whether to invite someone to our Services, follow them, or allow another Member to become your follower.</span></p>
          <p><span>Visitors have choices about how we use their data.</span></p>
          <p><strong><span>Stay Informed</span></strong></p>
          <p><span>Our Services allow you to stay informed about news, events and ideas regarding professional topics you care about, and from professionals you respect. Our Services also allow you to improve your professional skills, or learn new ones. We use the data we have about you (e.g., data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to personalize our Services for you, such as by recommending or ranking relevant professionals, content and conversations on our Services. We use your content, activity and other data, including your name and photo, to provide notices to your network and others.</span></p>
          <p><strong><span>3.2 Communications</span></strong></p>
          <p><span>We contact you and enable communication between Members. We offer settings to control what messages you receive and how often you receive some types of messages.</span></p>
          <p><span>We will contact you through email, mobile phone, notices posted on our websites or apps, messages to your Learnet inbox, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, job suggestions and promotional messages from us and our partners. You may change your communication preferences at any time. Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.</span></p>
          <p><strong><span>3.3 Developing Services and Research</span></strong></p>
          <p><span>We develop our Services and conduct research</span></p>
          <p><strong><span>Service Development</span></strong></p>
          <p><span>We use data, including public feedback, to conduct rese</span><span style="font-size:11.5pt;">arch</span><span>&nbsp;and development for our Services in order to provide you and others with a better, more intuitive and personalized experience, drive sign-up growth and engagement on our Services, and help connect professionals to each other.</span></p>
          <p><strong><span>Surveys</span></strong></p>
          <p><span>Polls and surveys are conducted by us and others through our Services. You are not obligated to respond to polls or surveys, and you have choices about the information you provide. You may opt-out of survey invitations.</span></p>
          <p><strong><span>3.4 Customer Support</span></strong></p>
          <p><span>We use data to help you and fix problems.</span></p>
          <p><span>We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</span></p>
          <p><strong><span>3.5 Security and Investigations</span></strong></p>
          <p><span>We use data for security, fraud prevention and investigations.</span></p>
          <p><span>We use your data (including your communications) for security purposes or to prevent or investigate possible fraud or other violations of our Terms of Use and/or attempts to harm our Members, Visitors or others.</span></p>
          <p><strong><span>Key Terms&nbsp;</span></strong></p>
          <p><strong><span>4. How We Share Information</span></strong></p>
          <p><strong><span>4.1 Our Services</span></strong></p>
          <p><span>Any data that you include on your profile and any content you post or social action (e.g., likes, follows, comments, shares) you take on our Services will be seen by others, consistent with your settings.</span></p>
          <p><strong><span>Profile</span></strong></p>
          <p><span>Your profile is fully visible to all Members and customers of our Services. Subject to your settings, it can also be visible to others on or off of our Services (e.g., Visitors to our Services or users of third- party search engines).</span></p>
          <p><strong><span>Posts, Likes, Follows, Comments, Messages</span></strong></p>
          <p><span>Our Services allow viewing and sharing information including through posts, likes, follows and comments.</span></p>
          <ul>
              <li>
                  <p><span>When you share an article or a post (e.g., an update, image, video or article) publicly it can be viewed by everyone and re-shared anywhere (subject to your settings). Members, Visitors and others will be able to find and see your publicly-shared content, including your name (and photo if you have provided one).</span></p>
              </li>
              <li>
                  <p><span>When you follow a person, you are visible to others and that &ldquo;page owner&rdquo; as a follower.</span></p>
              </li>
              <li>
                  <p><span>We let senders know when you act on their message, subject to your settings where applicable.</span></p>
              </li>
              <li>
                  <p><span>Subject to your settings, we let a Member know when you view their profile.</span></p>
              </li>
              <li>
                  <p><span>When you like or re-share or comment on another&rsquo;s content (including ads), others will be able to view these &ldquo;social actions&rdquo; and associate it with you (e.g., your name, profile and photo if you provided it).</span></p>
              </li>
          </ul>
          <p><strong><span>4.2 Others&rsquo; Services</span></strong></p>
          <p><span>You may link your account with others&rsquo; services so that they can look up your contacts&rsquo; profiles, post your shares on such platforms, or enable you to start conversations with your connections on such platforms. Excerpts from your profile will also appear on the services of others.</span></p>
          <p><span>Subject to your settings, other services may look up your profile. When you opt to link your account with other services, personal data will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts. For example, you may link your Linkedin, Twitter, Facebook or WeChat account to share content from our Services into these other services, or your email provider may give you the option to upload your Learnet contacts into its own service. Third-party services have their own privacy policies, and you may be giving them permission to use your data in ways we would not. You may revoke the link with such accounts.</span></p>
          <p><span>Subject to your settings, excerpts from your profile will appear on the services of others (e.g., search engine results, mail and calendar applications that show a user limited profile data of the person they are meeting or messaging, social media aggregators). &ldquo;Old&rdquo; profile information remains on these services until they update their data cache with changes you made to your profile.</span></p>
          <p><strong><span>4.3 Related Services</span></strong></p>
          <p><span>We share your data across our different Services and Learnet affiliated entities.</span></p>
          <p><span>We will share your personal data with our affiliates to provide and develop our Services. We may combine information internally across the different Services covered by this Privacy Policy to help our Services be more relevant and useful to you and others. For example, we may personalize your feed based on your learning history.</span></p>
          <p><strong><span>4.4 Service Providers</span></strong></p>
          <p><span>We may use others to help us with our Services.</span></p>
          <p><span>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</span></p>
          <p><strong><span>4.5 Legal Disclosures</span></strong></p>
          <p><span>We may need to share your data when we believe it&rsquo;s required by law or to help protect the rights and safety of you, us or others.</span></p>
          <p><span>It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of Learnet, our Members, personnel or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand.</span></p>
          <p><strong><span>4.6 Change in Control or Sale</span></strong></p>
          <p><span>We may share your data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.</span></p>
          <p><span>We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</span></p>
          <p><strong><span>5. Your Choices &amp; Obligations</span></strong></p>
          <p><strong><span>5.1 Data Retention</span></strong></p>
          <p><span>We keep most of your personal data for as long as your account is open.</span></p>
          <p><span>We generally retain your personal data as long as you keep your account open or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. In some cases we choose to retain certain information (e.g., insights about Services use) in a depersonalized or aggregated form.</span></p>
          <p><strong><span>5.2 Rights to Access and Control Your Personal Data</span></strong></p>
          <p><span>You can access or delete your personal data. You have many choices about how your data is collected, used and shared.</span></p>
          <p><span>For personal data that we have about you, you can:</span></p>
          <ul>
              <li>
                  <p><strong><span>Delete Data:</span></strong><span>&nbsp;You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you).</span></p>
              </li>
              <li>
                  <p><strong><span>Change or Correct Data:</span></strong><span>&nbsp;You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it&rsquo;s inaccurate.</span></p>
              </li>
              <li>
                  <p><strong><span>Object to, or Limit or Restrict, Use of Data:</span></strong><span>&nbsp;You can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held).</span></p>
              </li>
              <li>
                  <p><strong><span>Right to Access and/or Take Your Data:</span></strong><span>&nbsp;You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.</span></p>
              </li>
          </ul>
          <p><span>&nbsp;You may also contact us using the contact information we have provided, and we will consider your request in accordance with applicable laws.</span></p>
          <p><span>Residents in the Designated Countries and other regions may have additional rights under their laws.</span></p>
          <p><strong><span>5.3 Account Closure</span></strong></p>
          <p><span>We keep some of your data even after you close your account.</span></p>
          <p><span>If you choose to close your Learnet account, your personal data will generally stop being visible to others on our Services within 48 hours. We generally delete closed account information within 30 days of account closure, except as noted below.</span></p>
          <p><span>We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our Terms of Use, or fulfill your request to &quot;unsubscribe&quot; from further messages from us. We will retain de-personalized information after your account has been closed.</span></p>
          <p><span>Information you have shared with others will remain visible after you close your account or delete the information from your own profile or mailbox, and we do not control data that other Members have copied out of our Services. Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.</span></p>
          <p><strong><span>6. Other Important Information</span></strong></p>
          <p><strong><span>6.1. Security</span></strong></p>
          <p><span>We monitor for and try to prevent security breaches. Please use the security features available through our Services.</span></p>
          <p><span>We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</span></p>
          <p><strong><span>6.2. Cross-Border Data Transfers</span></strong></p>
          <p><span>We store and use your data outside your country.</span></p>
          <p><span>Currently, we process data inside of the United States and rely on legally-provided mechanisms to lawfully transfer data across borders.&nbsp;</span></p>
          <p><strong><span>6.3 Lawful Bases for Processing</span></strong></p>
          <p><span>We have lawful bases to collect, use and share data about you.</span></p>
          <p><span>We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g., to deliver the Learnet Services you have requested) and &ldquo;legitimate interests&rdquo;.</span></p>
          <p><span>Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object.</span></p>
          <p><strong><span>6.4. Direct Marketing and Do Not Track Signals</span></strong></p>
          <p><span>Our statements regarding direct marketing and &ldquo;do not track&rdquo; signals.</span></p>
          <p><span>We currently do not share personal data with third parties for their direct marketing purposes without your permission.&nbsp;</span></p>
          <p><strong><span>6.5. Contact Information</span></strong></p>
          <p><span>You can contact us or use other options to resolve any complaints.</span></p>
          <p><span>If you have questions or complaints regarding this Policy, please first email us at&nbsp;</span><span>support@learnet.ai</span><span>. You can also reach us by physical mail at 52 Pickwick Dr N, Syosset, NY 11791.</span></p>
          <p><span>&nbsp;</span></p>
          
          <p   style="text-align: center;"><strong><span>California Consumer Privacy Act</span></strong></p>
          <p><span>This notice describes the rights of Californians under the California Consumer Privacy Act of 2018, which was amended by the California Privacy Rights Act of 2020 (together, &ldquo;CCPA&rdquo;). The CCPA requires businesses like us to provide certain information to California consumers. We fully describe our practices to all our members and visitors in our Privacy Policy, but use this separate notice to ensure that we meet the CCPA&rsquo;s requirements. This notice contains the following four parts:</span></p>
          <ul>
              <li>
                  <p><span>You Can Opt Out of Our Sharing Your Personal Information. We Do Not Sell Your Personal Information.</span></p>
              </li>
              <li>
                  <p><span>Your CCPA Rights</span></p>
              </li>
              <li>
                  <p><span>The CCPA Categories of Personal Information We Collect and the Sources</span></p>
              </li>
              <li>
                  <p><span>The CCPA Categories of Personal Information We Disclose for a &ldquo;Business Purpose&rdquo;</span></p>
              </li>
          </ul>
          <p><strong><span>1. &nbsp; You Can Opt Out of Our Sharing Your Personal Information. We Do Not Sell Your Personal Information.</span></strong></h2>
          <p><em><span>To opt out of sharing your personal information, please send an email to support@learnet.ai.</span></em><span>&nbsp;In addition, in the U.S. we will automatically opt you out of sharing if we receive a Global Privacy Control signal from you that reflects your choice to opt-out of sharing.</span></p>
          <p><span>The CCPA requires us to disclose if we share your personal information. We may share your personal information, including information about your visits to Learnet, with our partners in order to market Learnet&rsquo;s services to you on other platforms.</span></p>
          <p><span>We do not sell your personal information.</span></p>
          <p><strong><span>2. &nbsp; Your Rights Under the CCPA</span></strong></h2>
          <p><span>The CCPA provides Californians with the following rights:</span></p>
          <p><strong><span>2.1 Requests for Information</span></strong></h3>
          <p><span>Like all of our members, you (or your authorized agent) can request a copy of your personal information. Under the CCPA, you can also request that we disclose how we have collected, used, and disclosed your personal information over the past 12 months, including the categories of personal information we collected and our purposes for doing so; the categories of sources for that information; the categories of third parties with whom we shared it or disclosed it for a business purpose and our purposes for doing so. Companies that sell personal information (we do not) must make additional disclosures.</span></p>
          <p><strong><span>2.2 Your Right to Opt Out of Sales</span></strong></h3>
          <p><span>We do not sell personal information, so we don&rsquo;t have an opt out.</span></p>
          <p><strong><span>2.3 Your Right to Opt Out of Sharing</span></strong></h3>
          <p><span>Under the CCPA, you can opt out of the sharing of your personal information with third parties for cross-context behavioral advertising purposes. You can direct us not to share your personal information as described in Section 1.</span></p>
          <p><strong><span>2.4 Your Right to Limit the Use of Sensitive Personal Information</span></strong></h3>
          <p><span>Under the CCPA, you can limit a business&rsquo;s use and disclosure of your sensitive personal information to certain purposes specified by law (e.g., providing you with services you request or preventing fraud, or for other purposes that don&rsquo;t involve deriving your attributes). We only use sensitive personal information for such permitted purposes, so we don&rsquo;t have an opt-out.</span></p>
          <p><strong><span>2.5 Your Right to Notification</span></strong></h3>
          <p><span>Under the CCPA, a business like Learnet cannot collect new categories of personal information or use them for materially different purposes without first notifying you.</span></p>
          <p><strong><span>2.6 Nondiscrimination for Exercising Your CCPA Rights</span></strong></h3>
          <p><span>The CCPA prohibits businesses from discriminating against you for exercising your rights under the law. Such discrimination may include denying services, charging different prices or rates for services, providing a different level or quality of services, or suggesting that you will receive a different level or quality of goods or services as a result of exercising your rights.</span></p>
          <p><strong><span>2.7 Your Right to Correct Inaccurate Personal Information</span></strong></h3>
          <p><span>Like all of our members, you can edit much of your personal information through your Learnet account (such as your profile information). You can also request that we correct inaccurate personal information.</span></p>
          <p><strong><span>2.8 Your Right to Delete Personal Information</span></strong></h3>
          <p><span>Like all of our members, you can request that we delete your personal information by closing your Learnet account or by requesting that we delete specific information about you. We honor such requests unless an exception applies, such as when the information is necessary to complete the transaction or contract for which it was collected or when information is being used to detect, prevent, or investigate security incidents, comply with laws, identify and repair bugs, or ensure another consumer&rsquo;s ability to exercise their free speech rights or other rights provided by law.</span></p>
          <p><strong><span>2.9 Statement Regarding Selling or Sharing the Personal Information of Consumers Under Age 16</span></strong></h3>
          <p><span>CCPA regulations require us to state whether we have actual knowledge that we have sold or shared the personal information of consumers under 16 years of age. As stated in our terms of use, Learnet services are not for use by anyone under the age of 16. We do not knowingly provide services to anyone under the age of 16 nor do we have actual knowledge that we sell or share the personal information of consumers under 16 years of age.</span></p>
          <p><strong><span>3. &nbsp; The CCPA Categories of Personal Information We Collect and the Sources</span></strong></h2>
          <div>
              <table>
                  <tbody>
                      <tr >
                          <td>
                              <p><strong><span><br></span></strong><span>CCPA Personal Information Category</span></p>
                          </td>
                          <td>
                              <p><span><br></span><span>Sources of this Information</span></p>
                          </td>
                      </tr>
                      <tr >
                          <td style="padding-right: 15px;" >
                              <p><span>Identifiers (e.g., real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers)</span></p>
                          </td>
                          <td style="padding-right: 15px;">
                              <p><span>Information you provide directly or through your interactions with our Services (as defined in our Privacy Policy) or partners (as described in Sections 1.2 and 2.4 of our Privacy Policy)</span></p>
                              <p><span>Vendors that provide information to help us serve members relevant ads and understand the ads&rsquo; effectiveness</span></p>
                              <p><span>Information from other Learnet members or customers</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p><span>Characteristics of protected classifications under California or Federal law (e.g., your gender or age) (&ldquo;Characteristics of Protected Classifications&rdquo;)</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide to us directly and inferences we make based on that information</span></p>
                              <p><span>Information from our customers</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td style="padding-right: 15px;">
                              <p><span>Commercial information (e.g., information regarding products or services purchased, obtained, or considered)</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide to us directly, such as to enroll as a mentor and get paid or to learn from mentors and pay for their services.</span></p>
                              <p><span>Your interactions with our Services</span></p>
                              <p><span>Information from our partners and customers</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td style="padding-right: 15px;">
                              <p><span>Internet or Other Electronic Network Activity Information (e.g., browsing history, search history, and information regarding your interactions with our Services)</span></p>
                          </td>
                          <td>
                              <p><span>Your interactions with our Services</span></p>
                              <p><span>Your visits to third party sites that offer our autofill, follow or &ldquo;Apply with Learnet&rdquo; functionality, as needed for fraud prevention and security purposes.</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p><span>Geolocation Data</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide to us directly or through your interactions with our Services, as explained in Section 1.5 of our Privacy Policy</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p><span>Professional or Employment-Related Information</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide to us directly or information that you add to your Learnet profile</span></p>
                              <p><span>Information from our customers, partners, or other members (e.g., if a connection endorses you)</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p><span>Inferences</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide to us directly or through your interactions with our Services</span></p>
                              <p><span>Information from our customers and partners</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td style="padding-right: 10px;">
                              <p><span>Personal information described in Cal. Civ. Code &sect;1798.80(e)(such as name, address, telephone number, education, employment history, credit card or debit card number)</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide directly or through your interactions with our Services</span></p>
                              <p><span>Information from our customers or partners</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p><span>Audio, electronic, visual or similar information</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide directly or through your interactions with our Services, customers, or partners</span></p>
                          </td>
                      </tr>
                      <tr>
                          <td >
                              <p><span>Sensitive personal information, which may include:</span></p>
                              <p><span>&nbsp;</span></p>
                              <p style="padding-right: 10px;"><span>- Data generated when you communicate on our services (e.g., messages to other members)</span></p>
                              <p><span>&nbsp;</span></p>
                              <p style="padding-right: 10px;"><span>- Data used to secure your account (e.g., login information or proof of identification if you are unable to verify your account)</span></p>
                              <p><span>&nbsp;</span></p>
                              <p style="padding-right: 10px;"><span>- Data you add to your profile or submit via your settings, which may include information about your race or ethnicity</span></p>
                          </td>
                          <td>
                              <p><span>Information you provide to us directly or through your interactions with our Services</span></p>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <p><span>Our Privacy Policy describes the information we collect and its sources. This notice organizes that description around the personal information categories set forth in the CCPA.</span></p>
          <p><span>CCPA Personal Information Categories and corresponding sources of this info.</span></p>
          <p><span>We use and retain this personal information for the purposes outlined in Sections 2 and 4.1 of our Privacy Policy. This means that we generally retain the types of personal information listed in the chart above as long as you keep your account open or as needed to provide you services.</span></p>
          <p><strong><span>4. &nbsp; The CCPA Categories of Personal Information We &ldquo;Share&rdquo;&nbsp;</span></strong></h2>
          <p><span>While we do not sell your personal information, we may engage in &ldquo;sharing&rdquo; under the CCPA, which broadly defines this term as disclosing personal information for purposes of cross-context behavioral advertising, which is defined to include instances where companies target advertising based on personal information obtained from a consumer&rsquo;s activity across distinctly-branded websites or services. Learnet&rsquo;s practices and the categories of data we share are described in Section 1. We do not share sensitive personal information.</span></p>
          <p><span>As described above, Learnet members can opt out of &ldquo;sharing&rdquo;. We will automatically opt you out of sharing if we receive a Global Privacy Control signal from you that reflects your choice to opt-out of sharing.</span></p>
          <p><strong><span>5. &nbsp; The CCPA Categories of Personal Information We Disclose for a &ldquo;Business Purpose&rdquo;&nbsp;</span></strong></h2>
          <p><span>The CCPA requires us to describe the &ldquo;business purposes&rdquo; for which we may disclose your personal information. As described in our Privacy Policy, we may disclose your personal information to support our own operational purposes in providing our Services to you. In addition, we may disclose personal information at your direction, such as when you choose to communicate with other members through our Services.</span></p>
          <p><strong><span>5.1 Auditing Interactions</span></strong></h3>
          <p><span>We may disclose the types of personal information listed in Section 3 with partners, service providers and related companies, in order to audit interactions and transactions, such as to count or verify the positioning and quality of ad impressions.</span></p>
          <p><strong><span>5.2 Security and Integrity</span></strong></h3>
          <p><span>In order to secure our Services, including to detect, prevent, and investigate security incidents or violations of our Professional Community Policies or applicable laws, we may disclose the types of personal information listed in Section 3 with our partners, service providers, law enforcement, and related companies.</span></p>
          <p><strong><span>5.3 Service Improvements</span></strong></h3>
          <p><span>In order to improve our Services (such as to identify bugs, repair errors, or ensure that services function as intended) or conduct internal research and analysis to improve our technology, we may disclose the types of personal information listed in Section 3 with our partners, service providers, and related companies.</span></p>
          <p><strong><span>5.4 Service Providers and Other Notified Purposes</span></strong></h3>
          <p><span>We may disclose the types of personal information listed in Section 3 with Service Providers, as defined by the CCPA, in order to have them perform services specified by a written contract or with others for a notified purpose permitted by the CCPA (e.g., to respond to law enforcement requests).</span></p>
          <p><strong><span>5.5 Marketing and Advertising</span></strong></h3>
          <p><span>Except as noted above, we may disclose the types of personal information listed in Section 3 with our partners, service providers, and related companies, in order for them to provide marketing and advertising services on our behalf as permitted by the CCPA (e.g., sending you communications).</span>
         `;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [iscode, setIscode] = useState(false);
    useEffect(() => {
        const params1 = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        }, []);
        let value = params1.code;

        if (value !== null) {
            setIscode(true);
            const code = value;
            //console.log(code)
            loadData(code)
        }
        //handleClick();
    }, []);

    const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const redirect_url = process.env.REACT_APP_REDIRECT_URL;

    const handleClick = useCallback(() => {
        // Get redirectUrl from localStorage
        const redirectUrl = localStorage.getItem("redirectUrl");
        // Clear local storage
        localStorage.clear();
        // Set redirectUrl in localStorage
        localStorage.setItem("redirectUrl", redirectUrl);
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=r_basicprofile,r_emailaddress`;
        window.location.href = url;
    }, [id, redirect_url]);

    const loadData = async (code) => {
        //console.log("redirectURL", redirectUrl)
        try {

            const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
            const redirect_url = process.env.REACT_APP_REDIRECT_URL;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",

                        //   'Authorization': 'Bearer ' + this.state.AccessToken
                    }
                }
            );
            const res = await response.json();
            const authorize = res
            let issuccess = res.success
            let verified = false

            if (!issuccess) {
                console.log(res.message);
                toast.error(res.message)
                setTimeout(() => {
                    window.location.href = "/";
                }, 1500);

            }
            else {
                issuccess = true;
                verified = res.data.data.isVerified
            }

            if (verified) {
                let allData = JSON.stringify(authorize.data);
                localStorage.setItem("userData", allData);
                const isSessionpage = localStorage.getItem("isSessionpage")
                if (!authorize.data.data.hasUserAddedSkills) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/mentee-topics");
                }
                else if (isSessionpage === "yes") {
                    window.location.replace(`${redirect_url}/session`)
                    localStorage.removeItem("isSessionpage")
                }
                else {
                    //get redirectUrl from localStorage
                    const redirectUrl = localStorage.getItem("redirectUrl");
                    if (!redirectUrl || redirectUrl === "null") {
                        window.location.replace(`${redirect_url}/home-page`)

                    }
                    else {
                        //navigate to redirectUrl
                        window.location.href = redirectUrl;
                    }
                }




            }
            else if (issuccess) {
                if (authorize.data.data.completedStep == 1) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    window.location.replace(`${redirect_url}/email`)
                    //navigate("/email");
                } else if (authorize.data.data.completedStep == 2) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/address");
                } else if (authorize.data.data.completedStep == 3) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/employement");
                }
                else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/mentee-topics");
                }
                else {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/email");
                }


            }
            else {
                navigate("/");
            }
        }
        catch (e) {
            console.log(e)
        }

    }

    let userAllDAta = JSON.parse(localStorage.getItem("userData"));

    return (

        <>
            {
                iscode ? <div class="defaultloader">
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#92278f"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    /></div>
                    :
                    <>

                        {userAllDAta && userAllDAta.token ? (
                            <Header />
                        ) : (
                            <Navbar handleClick={handleClick} />
                        )}
                        <CommenHero allName={headings} name1="Privacy Policy" />
                        <div class="privacy_padd">
                            <div>{ReactHtmlParser(html)}</div>
                        </div>
                        <Footer />
                    </>

            }


        </>




    );
};

export default PrivacyPolicy;
