
import { apiRequest } from "./apiRequest";

export const updateHeadlineAndName = async (userId,data) => {
    const api = await apiRequest({
        url: `/v1/user/${userId}/headlinename`,
        method: "put",
        body: data,
        header: true,
    });
    return api;
}

export const updateCompanyInfo = async (userId,data) => {
    const api = await apiRequest({
        url: `/v1/user/${userId}/companyinfo`,
        method: "put",
        body: data,
        header: true,
    });
    return api;
}