import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import { CreateCheckoutSessionApi } from "../../../../Services/UserAuth";
import axios from "axios";
import { toast } from "react-toastify";
import { sessionEnrollmentCheck } from "../../../../Services/GroupSessionApiCall";
import useUser from "../../../../hooks/useUser";

const SessionEnrollModal = ({ isOpen, handleClose, sessionData }) => {
   const { user } = useUser();
  // const API_Base_Url = process.env.REACT_APP_BACKEND_URL;
  let userBookingData = JSON.parse(localStorage.getItem("userBookingData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = localStorage.getItem("Mentor_id");
  const [stripPaymentUrl, setStripPaymentUrl] = useState(null);
  // const [price, setPrice] = useState(`${sessionData?.sessionDetail?.amountPerSeatDollar}` || 1);
  //const [price, setPrice] = useState(1);
  console.log("sessionDatasessionDatasessionData", sessionData);
 
  const [mentees, setMentees] = useState([
    {
      _id: user?._id,
      email: user?.email,
      firstName: user?.fullName?.split(" ")[0] || "",
      lastName: user?.fullName?.split(" ")[1] || "",
    },
  ]);

  const formattedFromTime =  moment.utc(sessionData?.sessionDetail?.sessionDate).format("hh:mm A")
  const formattedToTime = moment.utc(sessionData?.sessionDetail?.sessionDate).add( sessionData?.sessionDetail?.sessionDuration, "minutes").format("hh:mm A")

  const gotoPayment = async () => {
    try {
      const response = await sessionEnrollmentCheck(
        sessionData?.sessionDetail?._id,
        user?._id
      )

      if (response.status === 200) {
        localStorage.setItem("sessionId", sessionData?.sessionDetail?._id);
        const meetingData = {
          amount: sessionData?.sessionDetail?.amountPerSeatDollar,
          currency: "USD",
          sessionType: "GroupSession",
          mentees: mentees,
          date: sessionData?.sessionDetail?.sessionDate,
          time: moment.utc(sessionData?.sessionDetail?.sessionDate).format("hh:mm A"),
          duration: sessionData?.sessionDetail?.sessionDuration,
          timeZone: sessionData?.sessionDetail?.timeZone,
          skill: sessionData?.topicName,
          mentor: {
            id: userId,
            email: userBookingData?.email,
             fullName: sessionData?.mentorName,
          },
          sessionId: sessionData?.sessionDetail?._id,
        };
    
        createCheckoutSession(meetingData)
     
          .then((res) => {
            setStripPaymentUrl(res.data.checkoutUrl);
          })
          .catch((err) => {
            //setIsFetching(false)
            // setIsBookingClicked(false);
          });
        // setIsFetching(false);
      }else{
        toast.error(response.data.message);
        console.log("response", response);
      }
      
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const createCheckoutSession = async (meetingData) => {
    const response = await CreateCheckoutSessionApi(
      meetingData.amount,
      meetingData
    );
    return response;
  };

  return (
    <>
      {stripPaymentUrl ? (
        window.location.replace(stripPaymentUrl)
      ) : (
        <Modal show={isOpen} centered onHide={handleClose} size="lg">
          <Modal.Header closeButton className="border-0 ">
            <Modal.Title>{sessionData?.topicName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-4">
              <div className="mb-3  col-8">
                <i className="fa-solid bi bi-calendar-check pe-1"></i>
                {""}
                {moment.utc(sessionData?.sessionDetail?.sessionDate).format(
                  "ddd, DD MMM YYYY"
                )}
                {""} at {formattedFromTime} to {formattedToTime} (
                {moment.tz(sessionData?.sessionDetail?.timeZone).format("z")})
              </div>
              <div className="mb-3 col-4">
                <i className="fa-solid fa-user pe-1 "></i>{" "}
                {sessionData?.mentorName}
              </div>
            </div>
            <Form className="border p-3">
              <Form.Group as={Row} className="mb-1" controlId="sessionFee">
                <Form.Label column sm="3">
                  Session Fee
                </Form.Label>
                <Col sm="2">
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={`$${sessionData?.sessionDetail?.amountPerSeatDollar} Per Session`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="totalPayable">
                <Form.Label column sm="3">
                  Total Payable
                </Form.Label>
                <Col sm="2">
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={`$${sessionData?.sessionDetail?.amountPerSeatDollar}`}
                  />
                </Col>
                <Col sm="7">
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={gotoPayment}
                  >
                    Pay Now
                  </Button>
                </Col>
              </Form.Group>
            </Form>
            <div className="justify-content-center mt-4 mx-3">
              All purchases are backed by our{" "}
              <a href="#">Satisfaction Guarantee</a>. By enrolling, you agree to
              the <a href="/term-and-condtion">Terms of Service</a> and{" "}
              <a href="/privacy-policy">Privacy Policy</a>.
            </div>
            <p className="mt-3  mx-3">
              This class has a Limited Refund Policy. <a href="#">Learn More</a>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SessionEnrollModal;
