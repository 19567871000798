import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import useUser from "../../hooks/useUser";

const PlatformFeedbackForm = ({ data: setFeedbackGiven }) => {
  const { user, updateUser } = useUser();
  const [feedback, setFeedback] = useState("");
  const token = JSON.parse(localStorage.getItem("userData")).token;
  const storedUser = user || {};
  const handleFeedback = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!feedback) {
        toast.error("Please enter feedback");
        return;
      }

      if (feedback.length < 20) {
        toast.error("Please enter atleast 20 characters");
        return;
      }
      const data = {
        feedback: feedback,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/feedbacks`,
        data,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data.success === true) {
        toast.success("Thank you for your feedback!");
        setFeedback("");
        /* localStorage.setItem(
          "userProfileData",
          JSON.stringify({
            ...storedUser,
            hasPlatformFeedbackGiven: true,
          })
        ); */
        updateUser({
          ...storedUser,
          hasPlatformFeedbackGiven: true,
        });
        setFeedbackGiven(true)
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="card mt-3">
        <div className="card-header border-0">
          <h5 className="mb-0">Help Us shape a better experience!</h5>
        </div>
        <div className="card-body card-mentor-rating pt-0 position-relative ">
          <div className="mb-2">
            <textarea
              className="form-control fs-25"
              rows="3"
              id="comment"
              onChange={handleFeedback}
              value={feedback}
              style={{ fontSize: "12px" }}
              placeholder="Please share your thoughts, suggestions, or any feedback you have about our platform."
            ></textarea>
          </div>
          <div className="mb-3">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlatformFeedbackForm;
