import React, { useState } from "react";
import { SendInvitation, UnfollowAPI } from "../../../Services/obAuth";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text" style={{ maxWidth: "90%" }}>
      {isReadMore && text.length > 220 ? text.slice(0, 220) : text}
      {text.length > 250 && (
        <span
          onClick={toggleReadMore}
          className="read-or-hide"
          style={{ cursor: "pointer" }}
        >
          {isReadMore ? "...show more" : " show less"}
        </span>
      )}
    </p>
  );
};

function Myprofile(props) {
  const navigate = useNavigate();
  let userProfileData = JSON.parse(localStorage.getItem("userBookingData"));
  const isStudent = userProfileData?.isStudent || false;
  const bannerImage =
    userProfileData?.bannerImage || "/assets/images/bg/05.jpg";
  console.log("props in myprofile", props.userData.data.skills);

  const skillsListtop = props.userData.data.skills.filter(
    (item) => item.isTop === true
  );
  const userData = JSON.parse(localStorage.getItem("userData")); // used for token
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // settinf Date
  let fulldate = new Date(props.userData.data.joiningDate);

  const sendInvitation = (id) => {
    try {
      SendInvitation(userData.token, id)
        .then((res) => {
          props.LoadOtherProfile();
        })
        .catch();
    } catch (error) {}
  };

  const UnfollowUser = (id) => {
    try {
      UnfollowAPI(userData.token, id)
        .then((res) => {
          props.LoadOtherProfile();
        })
        .catch();
    } catch (error) {}
  };

  const handleOnclickMessage = () => {
    navigate(`/messagelearner/${props.userData.data._id}`);
  };

  return (
    <>
      <div className="card">
        {/* <!-- Cover image --> */}
        {/* <img
          className="h-200px rounded-top"
          style={{
            background: `url(${bannerImage}) no-repeat center`,
            backgroundSize: "cover",
          }}
          src={bannerImage}
        ></img> */}

        <img
          src={bannerImage}
          crossorigin="anonymous"
          alt="Description of the image"
         className="h-200px rounded-top"
        />
        {/* <!-- Card body START --> */}
        <div className="card-body py-0">
          <div className="d-sm-flex align-items-start text-center text-sm-start">
            <div>
              {/* <!-- Avatar --> */}
              <div class="profile-pic mt-n5 mb-3">
                {/*   <img src={userProfileData.profilePicture} id="output" width="" className="rounded-circle" /> */}
                <LazyLoadImage
                  src={userProfileData.profilePicture}
                  id="output"
                  width=""
                  className="rounded-circle"
                  crossorigin="anonymous"
                />
                {userProfileData?.isMentor &&
                  userProfileData?.mentorApproval == "Approved" &&
                  !userProfileData?.isTopMentor && (
                    <LazyLoadImage
                      src="/assets/images/mentor-badge.png"
                      alt=""
                      className="mentorbage-search "
                    />
                  )}
                {userProfileData?.isMentor &&
                  userProfileData?.mentorApproval == "Approved" &&
                  userProfileData?.isTopMentor && (
                    /*  < img src="/assets/images/topmentor.png" alt="" className="mentorbage-search" /> */
                    <LazyLoadImage
                      src="/assets/images/topmentor.png"
                      alt=""
                      className="mentorbage-search"
                    />
                  )}
              </div>
            </div>
            <div
              className="ms-sm-4 mt-sm-3 d-flex justify-content-between mt-2"
              style={{ width: "100%" }}
            >
              {/* <!-- Info --> */}
              <div>
                <h1 className="mb-0 h5">
                  {props.userData.data.fullName}{" "}
                  {props?.userData?.data?.linkedinProfileLink && (
                    <a
                      href={props.userData.data.linkedinProfileLink}
                      target="_blank"
                    >
                      {" "}
                      <i className="fa-brands fa-linkedin ms-1"></i>
                    </a>
                  )}
                </h1>
                <p>
                  <small>
                    {props.userData.data.totalFollowers} Followers |{" "}
                    {props.userData.data.totalFollowings} Following{" "}
                    {props?.userData.data?.UserType == "User" ? (
                      <>
                        | Joined in {monthNames[fulldate.getMonth()]}{" "}
                        {fulldate.getFullYear()}
                      </>
                    ) : null}
                  </small>
                </p>
              </div>

              {userData?.data?._id !== props.userData.data._id ? (
                <>
                  {" "}
                  {userData?.token &&
                    (props.userData.data.hasUserFollowing ? (
                      <>
                        <div className="d-flex align-items-center">
                          <span
                            onClick={handleOnclickMessage}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.55556 8.22222H11M6.55556 12.1111H14.3333M11 21C16.5229 21 21 16.5229 21 11C21 5.47716 16.5229 1 11 1C5.47716 1 1 5.47716 1 11C1 12.3301 1.25971 13.5997 1.73118 14.7606C1.82141 14.9828 1.86653 15.0939 1.88667 15.1837C1.90636 15.2714 1.91356 15.3364 1.91357 15.4266C1.91358 15.5186 1.89687 15.6188 1.86346 15.8193L1.20459 19.7724C1.13559 20.1864 1.10109 20.3934 1.16529 20.5431C1.22148 20.6741 1.32587 20.7786 1.45687 20.8347C1.60654 20.8989 1.81353 20.8644 2.22751 20.7954L6.18072 20.1366C6.38121 20.1031 6.48145 20.0864 6.57348 20.0864C6.6635 20.0864 6.72851 20.0937 6.81636 20.1133C6.90616 20.1334 7.01724 20.1786 7.23943 20.2688C8.40033 20.7403 9.66989 21 11 21Z"
                                stroke="#606060"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>

                          <button
                            className="btn btn-sm btn-xs btn_edit ms-2"
                            style={{
                              opacity: 1,
                              background: "#fff",
                              border: "1px solid #92278f",
                              height: "30px",
                              color: "#92278f",
                            }}
                            onClick={() =>
                              UnfollowUser(props.userData.data._id)
                            }
                          >
                            <i class="fa fa-check"></i> Following
                          </button>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center">
                          <span
                            onClick={handleOnclickMessage}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.55556 8.22222H11M6.55556 12.1111H14.3333M11 21C16.5229 21 21 16.5229 21 11C21 5.47716 16.5229 1 11 1C5.47716 1 1 5.47716 1 11C1 12.3301 1.25971 13.5997 1.73118 14.7606C1.82141 14.9828 1.86653 15.0939 1.88667 15.1837C1.90636 15.2714 1.91356 15.3364 1.91357 15.4266C1.91358 15.5186 1.89687 15.6188 1.86346 15.8193L1.20459 19.7724C1.13559 20.1864 1.10109 20.3934 1.16529 20.5431C1.22148 20.6741 1.32587 20.7786 1.45687 20.8347C1.60654 20.8989 1.81353 20.8644 2.22751 20.7954L6.18072 20.1366C6.38121 20.1031 6.48145 20.0864 6.57348 20.0864C6.6635 20.0864 6.72851 20.0937 6.81636 20.1133C6.90616 20.1334 7.01724 20.1786 7.23943 20.2688C8.40033 20.7403 9.66989 21 11 21Z"
                                stroke="#606060"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          <button
                            className="btn btn-sm btn-xs btn_edit ms-2"
                            style={{
                              opacity: 1,
                              background: "#fff",
                              border: "1px solid #92278f",
                              height: "30px",
                              color: "#92278f",
                            }}
                            onClick={() =>
                              sendInvitation(props.userData.data._id)
                            }
                          >
                            Follow
                          </button>
                        </div>{" "}
                      </>
                    ))}{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <!-- List profile --> */}
          {props?.userData.data?.UserType == "User" ? (
            <>
              <ul className="list-inline mb-0 text-sm-start mt-3 mt-sm-0">
                {!isStudent && (
                  <li className="list-inline-item">
                    <small>
                      <i className="bi bi-briefcase me-1"></i>
                      {props.userData.data.currentJobTitle}
                    </small>
                  </li>
                )}
                <li className="list-inline-item">
                  <small>
                    <i className="bi bi-geo-alt me-1"></i>{" "}
                    {props.userData.data.location}
                  </small>
                </li>
              </ul>
              <ul className="list-inline mb-0 text-sm-start mt-3 mt-sm-0">
                {skillsListtop.length > 0 && (
                  <li className="list-inline-item d-flex">
                    {/*  <img src="/assets/images/crown.svg" alt="" width="12px" /> */}
                    <LazyLoadImage
                      src="/assets/images/crown.svg"
                      alt=""
                      width="12px"
                    />
                    &nbsp;
                    {skillsListtop.slice(0, 3).map((item, i) => (
                      <>
                        {" "}
                        <small>{item.title}</small>{" "}
                        {i != skillsListtop.slice(0, 3).length - 1 ? "," : ""}{" "}
                      </>
                    ))}
                  </li>
                )}
              </ul>
            </>
          ) : null}
        </div>
        {/* <!-- Card body END --> */}
        {userProfileData?.bio !== undefined &&
        userProfileData?.bio !== null &&
        userProfileData?.bio !== "" ? (
          <div className="card-body">
            <h5 className="card-title text-purple">Overview</h5>
            <div
              className="rounded pe-3 py-2"
              style={{ maxHeight: "120px", marginBottom: "0px" }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h5></h5>
              </div>
              <ReadMore children={userProfileData?.bio} />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "10px" }}></div>
        )}
      </div>
    </>
  );
}

export default Myprofile;
